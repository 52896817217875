<app-algolia-date-select *ngIf="!datePickerVisible">
</app-algolia-date-select>
<div
	class="l-custom-date-toggle"
	(click)="toggleDatePicker()"
>{{ datePickerVisible ? 'Hide Custom Date' : 'Select Custom Dates' }}</div>
<div *ngIf="datePickerVisible">
	<app-algolia-calendar-range [hideCalendar]="datePickerVisible"></app-algolia-calendar-range>
</div>
<div class="l-filter-heading-container">
	<div class="l-filter-heading">Type</div>
	<ais-clear-refinements
		[includedAttributes]="['Type']"
		resetLabel="Clear All"
	>
	</ais-clear-refinements>
</div>
<app-algolia-refinement-list
	attribute="Type"
	showMore="false"
></app-algolia-refinement-list>

<div class="l-filter-heading-container">
	<div class="l-filter-heading">City</div>
	<ais-clear-refinements
		[includedAttributes]="['City']"
		resetLabel="Clear All"
	>
	</ais-clear-refinements>
</div>
<app-algolia-refinement-list
	attribute="City"
	[refinementValue]="locationFilter?.city"
></app-algolia-refinement-list>

<div class="l-filter-heading-container">
	<div class="l-filter-heading">Category</div>
	<ais-clear-refinements
		[includedAttributes]="['Categories']"
		resetLabel="Clear All"
	>
	</ais-clear-refinements>
</div>
<app-algolia-refinement-list
	attribute="Categories"
	[refinementValue]="categoryFilter"
></app-algolia-refinement-list>

<div class="l-filter-heading-container">
	<div class="l-filter-heading">Country</div>
	<ais-clear-refinements
		[includedAttributes]="['Country']"
		resetLabel="Clear All"
		autoHideContainer=true
	>
	</ais-clear-refinements>
</div>
<app-algolia-refinement-list
	attribute="Country"
	[refinementValue]="locationFilter?.country"
></app-algolia-refinement-list>

<div class="l-filter-heading-container">
	<div class="l-filter-heading">Region</div>
	<ais-clear-refinements
		[includedAttributes]="['Region']"
		resetLabel="Clear All"
	>
	</ais-clear-refinements>
</div>
<app-algolia-refinement-list
	attribute="Region"
	[refinementValue]="locationFilter?.region"
></app-algolia-refinement-list>

<div class="l-filter-heading-container">
	<div class="l-filter-heading">Venue</div>
	<ais-clear-refinements
		[includedAttributes]="['VenueName']"
		resetLabel="Clear All"
	>
	</ais-clear-refinements>
</div>
<app-algolia-refinement-list attribute="VenueName"></app-algolia-refinement-list>