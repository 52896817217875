import { Component, Inject, Optional, forwardRef } from '@angular/core';
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch';
import { StatsWidgetDescription, StatsConnectorParams } from 'instantsearch.js/es/connectors/stats/connectStats';
import { connectStats } from 'instantsearch.js/es/connectors';

@Component({
	selector: 'app-algolia-stats',
	templateUrl: './algolia-stats.component.html',
	styleUrls: ['./algolia-stats.component.sass'],
})
export class AlgoliaStatsComponent extends TypedBaseWidget<StatsWidgetDescription, StatsConnectorParams> {
	state: StatsWidgetDescription['renderState'] = {
		nbHits: 0,
		areHitsSorted: true,
		nbPages: 0,
		page: 0,
		processingTimeMS: 0,
		query: null,
	};

	constructor(
		@Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
		@Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch
	) {
		super('Stats');
		this.createWidget(connectStats, {
			attribute: 'products',
		  });
	}

	public ngOnInit() {
		super.ngOnInit();
	}
}
