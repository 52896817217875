<label class="l-label">Dates</label>
<mat-select
	[(value)]="selectedOption"
	(selectionChange)="onSelectionChange($event.value)"
	class="form-control l-select"
	placeholder="Filter by Date"
>
	<mat-option
		*ngFor="let option of state.items"
		[value]="option.value"
	>
		{{ option.label }}
	</mat-option>
</mat-select>