import { Component } from '@angular/core';
import { SectionContent, Type } from '../../models/element.model';
import { URLCreator } from '@services/url/url.dictionary';

@Component({
	selector: 'app-how-it-works-section',
	templateUrl: './how-it-works-section.component.html',
	styleUrls: ['./how-it-works-section.component.sass'],
})
export class HowItWorksSectionComponent {
	imageSource = '/app-elements/assets/Ticketing_HowItWorks_1.png';
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'HOW IT WORKS',
		headingText: 'Setup',
		contentText: `Create, update, and customise your event from the dashboard anytime.
		Submit when ready and update wherever, whenever.`,
		buttonType: Type.PRIMARY,
		buttonText: 'CREATE EVENT',
		buttonLink: URLCreator.createEvent(),
	};
}
