<div class="l-socials-container">
	<a
		[href]="linkedin"
		target="_blank"
	>
		<button
			[ngClass]="customStyle"
		>
			<i class="fa-brands fa-linkedin"></i>
		</button>
	</a>
	<a
		[href]="twitter"
		target="_blank"
	>
		<button
			[ngClass]="customStyle"
		>
			<i class="fa-brands fa-x-twitter"></i>
		</button>
	</a>
	<a
		[href]="instagram"
		target="_blank"
	>
		<button
			[ngClass]="customStyle"
		>
			<i class="fa-brands fa-instagram"></i>
		</button>
	</a>
	<a
		[href]="facebook"
		target="_blank"
	>
		<button
			[ngClass]="customStyle"
		>
			<i class="fa-brands fa-facebook"></i>
		</button>
	</a>
</div>