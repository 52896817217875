<swiper-container
	space-between="30"
	slides-per-view="auto"
	autoplay-delay="3000"
	autoplay-disable-on-interaction="false"
	pagination="true"
	grab-cursor="true"
>
	<swiper-slide
		*ngFor="let card of cards"
		class="l-swiper-cards"
		[ngClass]="{'l-swiper-testimonial-cards': type === cardType.TESTIMONIAL, 'l-swiper-feature-cards': type === cardType.FEATURE}"
	>
		<app-testimonial-card
			*ngIf="type === cardType.TESTIMONIAL"
			class="l-swiper-testimonial-card"
			[card]="card"
		>
		</app-testimonial-card>
		<app-feature-card
			*ngIf="type === cardType.FEATURE"
			class="l-swiper-feature-card"
			[card]="card"
		>
		</app-feature-card>
	</swiper-slide>
</swiper-container>