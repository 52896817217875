<div class="l-container">
	<div class="l-content">
		<div class="l-details-container">
			<div class="l-detail">
				<div class="l-heading">
					<i class="fa fa-phone fa-fw"></i>
					<div class="l-secondary-heading">Phone</div>
				</div>
				<div class="l-text">{{ domainData.telephoneNumber }}</div>
			</div>
			<div class="l-detail">
				<div class="l-heading">
					<i class="fa fa-envelope fa-fw"></i>
					<div class="l-secondary-heading">Running an event?</div>
				</div>
				<div class="l-text">{{ sales }}</div>
			</div>
			<div class="l-detail">
				<div class="l-heading">
					<i class="fa fa-envelope fa-fw"></i>
					<div class="l-secondary-heading">Help buying tickets?</div>
				</div>
				<div class="l-text">{{ support }}</div>
			</div>
			<div class="l-detail">
				<div class="l-heading">
					<i class="fa fa-envelope fa-fw"></i>
					<div class="l-secondary-heading">Help with your events</div>
				</div>
				<div class="l-text">{{ organiser }}</div>
			</div>
		</div>
		<div class="l-help-container">
			<div class="l-heading">
				<i class="fa fa-info-circle fa-fw"></i>
				<div class="l-primary-heading">Help Yourself</div>
			</div>
			<div class="l-text">Our help center is full of useful help articles on how to use Quicket!</div>
			<a
				class="g-primary-button"
				href="https://help.quicket.com/portal/en/kb"
				target="_blank"
			>Visit the help center</a>
		</div>
	</div>
	<div
		*ngIf="contactsLoading; else map"
		>
		<div class="l-page-loader">
			<mat-spinner></mat-spinner>
		</div>
	</div>
	<ng-template #map>
		<iframe
			[src]='mapSrc'
			width="100%"
			height="400"
			style="border:0;"
			allowfullscreen=""
			loading="lazy"
			referrerpolicy="no-referrer-when-downgrade"
			class="l-map"
		></iframe>
	</ng-template>
</div>
