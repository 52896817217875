
<div *ngIf="currencies && selectedCurrency" class="l-container">
    <app-sales-estimate-currency-selector
    [currencies]="currencies"
    (currencySelected)="updateSelectedCurrency($event)"
    >
    </app-sales-estimate-currency-selector>
    <app-sales-estimate-calculator class="l-calc-container"
        [selectedCurrency]="selectedCurrency"
    >
    </app-sales-estimate-calculator>
</div>
