<div class="l-container">
	<app-page-section
		class="l-section-container"
		[imageSource]="imageSource"
		[textContent]="textContent"
	> </app-page-section>
	<div class="l-trusted-container">
		<div class="l-trusted-text">We are trusted by 100,000+ events, including:</div>
		<img
			class="l-trusted-image"
			[src]="trustedImageSource"
		/>
	</div>
</div>