<div class="l-fees-container" [ngClass]="{'mobile': isMobile || isSmall}">
    <div class="l-icon-sprinkle l-top-left" *ngIf="!isMobile && !isSmall">
        <div class="l-icon-container">
            <img src="/app-elements/assets/icons/stripe.svg">
        </div>
    </div>
    <div class="l-icon-sprinkle l-top-right" *ngIf="!isMobile && !isSmall">
        <div class="l-icon-container">
            <img class="p-1" src="/app-elements/assets/icons/visa.svg">
        </div>
    </div>
    <div class="l-container">
        <div class="l-charges">
            <div class="l-commission">
                <div class="l-commission-amount">
                    {{pricing.commission | percent: '1.0-1' }}
                </div>
                <div class="l-info">Commission ex VAT</div>
            </div>
            <i class="fa fa-solid fa-plus l-icon"></i>
            <div class="l-booking">
                <div class="l-booking-amount">
                    {{selectedCurrency.symbol}}{{pricing.serviceFee }}
                </div>
                <div class="l-info">per ticket</div>
            </div>
        </div>
        <hr class="l-line">
        <div class="l-definition-container">
            <p>These fees are charged per event and cover all costs including banking, credit card processing and support.</p>
            <p><a [href]="contactUs">Please get in touch</a> if you'd like your ticket buyers to pay the commission.</p>
        </div>
    </div>
    <div class="l-icon-sprinkle l-bottom-left" *ngIf="!isMobile && !isSmall">
        <div class="l-icon-container">
            <img src="/app-elements/assets/icons/mastercard.svg">
        </div>
    </div>
</div>