import { Component, Inject, Optional } from '@angular/core';
import { SIDENAV_ITEMS, SIDENAV_ITEMS_LOGGED_IN } from '../../utils/consts';
import { OVERLAY_DATA, OverlayService } from '@services/overlay/overlay.service';
import { NavItem } from '../../models/element.model';
import { URLCreator } from '@app/services/url/url.dictionary';
import { QUICKET_LOGO } from '@app/utils/consts';

@Component({
	selector: 'app-mobile-side-nav',
	templateUrl: './mobile-side-nav.component.html',
	styleUrls: ['./mobile-side-nav.component.sass'],
})
export class MobileSideNavComponent {
	navOptions: NavItem[];

	QUICKET_LOGO = QUICKET_LOGO;

	myAccountsURL = URLCreator.userProfile();

	constructor(
		private overlayService: OverlayService,
		@Optional()
		@Inject(OVERLAY_DATA)
		public data: {
			email: string;
		}
	) {
		this.navOptions = this.data.email ? SIDENAV_ITEMS_LOGGED_IN : SIDENAV_ITEMS;
	}

	ngOnInit(): void {}

	handleClose() {
		this.overlayService.close();
	}

	handleCreateEvent() {
		window.location.href = URLCreator.createEvent();
	}

	handleSignIn() {
		window.location.href = URLCreator.login(URLCreator.manageTicketDashboard());
	}
}
