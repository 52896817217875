export enum Type {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
}

export enum CardType {
	TESTIMONIAL = 'testimonial',
	FEATURE = 'feature',
}

export enum Categories {
	'Music' = '1',
	'Science & Technology' = '2',
	'Film & Media' = '3',
	'Sports & Fitness' = '5',
	'Travel & Outdoor' = '6',
	'Arts & Culture' = '9',
	'Food & Drink' = '12',
	'Business & Industry' = '13',
	'Health & Wellness' = '38',
	'Faith & Spirituality' = '46',
	'Charity & Causes' = '50',
	'Hobbies & Interests' = '62',
	'Other' = '64',
}

export interface SectionContent {
	headingType: Type;
	headingText: string;
	contentText?: string;
	buttonType?: Type;
	buttonText?: string;
	buttonLink?: string;
	secondaryHeadingText?: string;
}

export interface FeatureCard {
	image: string;
	title: string;
	text: string;
	subTitle?: string;
	authorTitle?: string;
	link?: string;
}

export interface NavItem {
	label: string;
	href?: string;
	options?: NavItem[];
	highlight?: boolean;
	extraClass?: string;
	target?: string;
}

export interface DateFilterOption {
	label: string;
	start: number;
	end: number;
}

export class LocationFilters {
	country?: string;
	region?: string;
	city?: string;
}
