<div class="l-container">
	<div class="l-content">
		<div class="l-header">
			<img
				class="l-logo"
				[src]="QUICKET_LOGO"
				alt="logo"
			>
			<button
				(click)="handleClose()"
				class="l-button"
			>
				<i class="fa fa-solid fa-times l-close"></i>
			</button>

		</div>
		<div class="l-links">
			<div
				class="l-link"
				*ngFor="let option of navOptions"
			>
				<div *ngIf="option.options; else singleLink">
					<mat-accordion multi="true">
						<mat-expansion-panel class="menu">
							<mat-expansion-panel-header class="menu">
								<mat-panel-title class="g-subtitle l-href">
									{{ option.label }}
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="l-expansion">
								<a
									*ngFor="let item of option.options"
									class="l-menu-link"
									[href]="item.href"
									[target]="item.target ? item.target : undefined"
								>{{item.label | titlecase}}
								</a>
								<ng-container *ngIf="option.label === 'Resources'">
									<div
										id="ot-sdk-btn"
										class="ot-sdk-show-settings l-menu-link l-cookie-settings"
									>Cookie Settings</div>
								</ng-container>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<ng-template #singleLink>
					<a
						class="g-subtitle l-href l-single-link"
						[href]="option.href"
					>{{option.label}}
					</a>
				</ng-template>
			</div>
		</div>
	</div>
	<div class="l-footer-container">
		<hr />
		<div class="g-subtitle mb-3">Follow Us</div>
		<div class="l-footer">
			<div class="l-socials">
				<app-quicket-socials
					class="l-quicket-socials"
					customStyle='l-social-button-mobile-side-nav'
				></app-quicket-socials>
			</div>
			<div
				*ngIf="data.email; else signup"
				class="l-signup-container"
			>
				<a
					[href]="myAccountsURL"
					class="l-menu-link"
				>
					<div class="l-current-user">
						<i class="fa fa-solid fa-user"></i>
						<div class="l-username">{{ data.email }}</div>
					</div>
				</a>
				<button
					(click)="handleCreateEvent()"
					id='create-event'
					class="g-primary-button w-100 my-2"
				>CREATE EVENT
				</button>

			</div>
			<ng-template #signup>
				<button
					(click)="handleSignIn()"
					id='signup'
					class="g-primary-button w-100 my-2"
				>SIGN IN
				</button>
			</ng-template>
		</div>
	</div>
</div>