import { Component, Input, OnInit } from '@angular/core';
import { Type } from '../../models/element.model';

@Component({
	selector: 'app-content-heading',
	templateUrl: './content-heading.component.html',
	styleUrls: ['./content-heading.component.sass'],
})
export class ContentHeadingComponent implements OnInit {
	primaryText: string;
	lastWord: string;

	type = Type;
	@Input() headingType: string;
	@Input() text: string;
	@Input() secondaryText: string;

	ngOnInit(): void {
		if (this.headingType === Type.PRIMARY) {
			const words = this.text.split(' ');
			this.lastWord = words.pop() || '';
			this.primaryText = words.join(' ');
		}
	}
}
