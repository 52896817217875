import { Component } from '@angular/core';
import { SectionContent, Type } from '../../models/element.model';

@Component({
	selector: 'app-ticketing-banner',
	templateUrl: './ticketing-banner.component.html',
	styleUrls: ['./ticketing-banner.component.sass'],
})
export class TicketingBannerComponent {
	altButtonLink = '#mobileCalc';
	imageSource = '/app-elements/assets/Ticketing_Hero.png';
	textContent: SectionContent = {
		headingType: Type.PRIMARY,
		headingText: 'Complete Control Over Your Tickets',
		contentText:
		`Everything you need to go live in just a few quick steps. 
		No hidden fees, no hassles — just the joy of making great events happen.`,
		buttonType: Type.PRIMARY,
		buttonText: 'VIEW TICKET CALCULATOR',
		buttonLink: '#calculator',
	};
}
