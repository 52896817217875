import { Component, Inject, Input, OnChanges, Optional, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch';
import { connectRange } from 'instantsearch.js/es/connectors';
import { RangeWidgetDescription, RangeConnectorParams } from 'instantsearch.js/es/connectors/range/connectRange';

@Component({
	selector: 'app-algolia-calendar-range',
	templateUrl: './algolia-calendar-range.component.html',
	styleUrls: ['./algolia-calendar-range.component.sass'],
	providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
})
export class AlgoliaCalendarRangeComponent extends TypedBaseWidget<RangeWidgetDescription, RangeConnectorParams> implements OnChanges {
	@Input() hideCalendar: boolean;
	state: RangeWidgetDescription['renderState'] = {
		refine: () => null,
		range: { min: 0, max: 0 },
		start: [0, 0],
		canRefine: false,
		sendEvent: () => {},
		format: {
			from: (value: number) => new Date(value * 1000).toLocaleDateString(),
			to: (value: number) => new Date(value * 1000).toLocaleDateString(),
		},
	};

	@ViewChild('calendar') calendar: MatCalendar<Date> | undefined;

	selectedDateRange: DateRange<Date> | undefined;

	constructor(
		@Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
		@Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch,
		private readonly selectionModel: MatRangeDateSelectionModel<Date>,
		private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>
	) {
		super('Range');
		this.createWidget(connectRange, {
			attribute: 'Dates',
		});
	}

	public ngOnInit() {
		super.ngOnInit();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hideCalendar) {
			this.handleClearDates();
		}
	}

	rangeChanged(selectedDate: Date) {
		const selection = this.selectionModel.selection;
		const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);

		this.selectionModel.updateSelection(newSelection, this);
		this.selectedDateRange = new DateRange<Date>(newSelection.start, newSelection.end);

		if (!this.selectedDateRange.end) {
			const endDate = new Date(newSelection.start);
			endDate.setDate(endDate.getDate() + 1);
			endDate.setSeconds(endDate.getSeconds() - 1);
			this.selectedDateRange = new DateRange<Date>(newSelection.start, endDate);
		}
	}

	handleApplyDates() {
		if (this.selectedDateRange.start && this.selectedDateRange.end) {
			const start = Math.floor(this.selectedDateRange.start.getTime() / 1000);
			const end = Math.floor(this.selectedDateRange.end.getTime() / 1000);
			this.state.refine([start, end]);
		}
	}

	handleClearDates() {
		this.selectedDateRange = null;
		this.state.refine([undefined, undefined]);
	}
}
