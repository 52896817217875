<div
	*ngIf="headingType === type.PRIMARY"
	class="l-primary-title"
>
	{{ primaryText }} <span class="l-green">{{ lastWord }}</span>
</div>

<div *ngIf="headingType === type.SECONDARY">
	<div class="l-secondary-sub-title">{{ secondaryText }}</div>
	<div class="l-secondary-title">{{ text }}</div>
</div>