import { Component, Inject, Input, OnInit, Optional, forwardRef } from '@angular/core';
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch';
import { connectRefinementList } from 'instantsearch.js/es/connectors';
import {
	RefinementListWidgetDescription,
	RefinementListConnectorParams
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

@Component({
	selector: 'app-algolia-refinement-list',
	templateUrl: './algolia-refinement-list.component.html',
	styleUrls: ['./algolia-refinement-list.component.sass'],
})
export class AlgoliaRefinementListComponent
	extends TypedBaseWidget<RefinementListWidgetDescription, RefinementListConnectorParams>
	implements OnInit {
	@Input() attribute: string;
	@Input() limit = 3;
	@Input() showMore = true;
	@Input() refinementValue = '';
	isShowingMore = false;

	state: RefinementListWidgetDescription['renderState'] = {
		items: [],
		hasExhaustiveItems: true,
		createURL: null,
		refine: () => '',
		sendEvent: () => null,
		searchForItems: () => null,
		isFromSearch: true,
		canRefine: true,
		canToggleShowMore: this.showMore,
		isShowingMore: false,
		toggleShowMore: () => null,
	};

	private initialRefinementApplied = false;

	constructor(
		@Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
		@Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch
	) {
		super('RefinementList');
	}

	public ngOnInit() {
		this.createWidget(connectRefinementList, {
			attribute: this.attribute,
			limit: this.limit,
			showMore: this.showMore,
		});
		super.ngOnInit();

		const customWidget = {
			$$type: 'custom.widget-' + this.attribute,
			render: () => {
				if (!this.initialRefinementApplied && this.refinementValue && this.state.refine) {
					this.state.refine(this.refinementValue);
					this.initialRefinementApplied = true;
				}
			},
		};

		this.instantSearchInstance.addWidgets([customWidget]);
	}

	toggleCheckbox(value: string, event: MouseEvent): void {
		event.stopPropagation();
		this.onSelectionChange(value);
	}

	onSelectionChange(value: string) {
		if (this.state.refine) {
			this.state.refine(value);
			this.isShowingMore = this.state.items.length > this.limit;
		}
	}

	toggleShowMore() {
		if (this.state.toggleShowMore) {
			this.state.toggleShowMore();
		}
	}
}
