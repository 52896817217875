import { Component, Inject, forwardRef, Optional, Input } from '@angular/core';
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';
import {
	AutocompleteWidgetDescription,
	AutocompleteConnectorParams
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';

@Component({
	selector: 'app-algolia-autocomplete',
	templateUrl: './algolia-autocomplete.component.html',
	styleUrls: ['./algolia-autocomplete.component.sass'],
})
export class AlgoliaAutocompleteComponent extends TypedBaseWidget<AutocompleteWidgetDescription, AutocompleteConnectorParams> {
	state: AutocompleteWidgetDescription['renderState'] = {
		currentRefinement: '',
		refine: () => null,
		indices: [],
	};

	@Input() isMobile = false;

	constructor(
		@Inject(forwardRef(() => NgAisIndex))
		@Optional()
		public parentIndex: NgAisIndex,
		@Inject(forwardRef(() => NgAisInstantSearch))
		public instantSearchInstance: NgAisInstantSearch
	) {
		super('Autocomplete');
		// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
		this!.createWidget(connectAutocomplete, {});
	}

	public handleChange($event: KeyboardEvent) {
		this.state.refine(($event.target as HTMLInputElement).value);
	}

	public ngOnInit() {
		super.ngOnInit();
	}

	handleNavigation(option: any, indexId: string) {
		switch (indexId) {
			case 'products':
				window.location.href = option.ProductUrl;
				break;
			case 'categories':
				window.location.href = option.CategoryUrl;
				break;
			case 'localities':
				window.location.href = option.LocalityUrl;
				break;
			default:
				return '';
		}
	}

	handleEnter(searchTerm: string) {
		window.location.href = '/events/?search=' + searchTerm;
	}

}
