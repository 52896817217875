<div>
	<mat-icon class="l-icon">search</mat-icon>
	<input
		[matAutocomplete]="auto"
		(keyup)="handleChange($event)"
		(keyup.enter)="handleEnter($event.target.value)"
		class="form-control l-search"
	/>
	<mat-autocomplete
		#auto="matAutocomplete"
		panelWidth="400px"
	>
		<div *ngFor="let index of state.indices.slice(1) || []">
			<mat-optgroup [label]="index.indexId === 'products' ? 'Events (' + index.results.nbHits + ')' : 
                           index.indexId === 'categories' ? 'Categories (' + index.results.nbHits + ')' : 
                           index.indexId === 'localities' ? 'Localities (' + index.results.nbHits + ')' : 'Other'">
				<ng-container [ngSwitch]="index.indexId">
					<mat-option
						*ngFor="let option of index.hits"
						(click)="handleNavigation(option, index.indexId)"
					>
						<ng-container *ngSwitchCase="'products'">
							<div class="l-event-container">
								<img
									*ngIf="!isMobile"
									class="l-event-image"
									[src]="option.ImageUrl_Small | safeUrl"
									[alt]="option.ProductName"
								/>
								<div class="l-event-content">
									<div class="l-event-title">
										<ais-highlight
											[hit]="option"
											attribute="ProductName"
										></ais-highlight>
									</div>
									<div class="l-event-description">
										{{ option.Categories.length ? option.Categories[0] : '-'}}
									</div>
									<div class="l-event-date">
										{{ option.DateFrom * 1000 | date: 'mediumDate'}}
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'categories'">
							{{ option.Category }}
						</ng-container>
						<ng-container *ngSwitchCase="'localities'">
							{{ option.Locality }}
						</ng-container>
					</mat-option>
				</ng-container>
			</mat-optgroup>
		</div>
	</mat-autocomplete>
</div>