import { Component, ViewEncapsulation } from '@angular/core';
import { FeatureCard } from '../../models/element.model';
import { BLOGS } from '../../utils/consts';

@Component({
	selector: 'app-blog-section',
	templateUrl: './blog-section.component.html',
	styleUrls: ['./blog-section.component.sass'],
	encapsulation: ViewEncapsulation.None,
})
export class BlogSectionComponent {
	cards: FeatureCard[] = BLOGS;

}
