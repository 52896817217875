<div class="l-blog-container">
	<div class="l-heading-container">
		<div class="l-secondary-sub-title">Our Blog</div>
		<div class="l-secondary-title">Resources For Creators</div>
	</div>
	<swiper-container
		class="l-swiper-blog-container"
		pagination="true"
		effect="coverflow"
		grab-cursor="true"
		centered-slides="true"
		slides-per-view="auto"
		coverflow-effect-rotate="0"
		coverflow-effect-stretch="0"
		coverflow-effect-depth="100"
		coverflow-effect-modifier="1"
		coverflow-effect-slide-shadows="false"
		initial-slide="3"
	>
		<swiper-slide
			*ngFor="let card of cards"
			class="l-swiper-blog-cards"
		>
			<app-blog-card
				class="l-swiper-blog-card"
				[card]="card"
			>
			</app-blog-card>
		</swiper-slide>
	</swiper-container>


</div>