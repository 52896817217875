<div class="l-container" id="calculator" #calculator *ngIf="!isMobile && !isSmall; else mobileCalc">
    <div>
        <app-content-container class="l-content-container" [contents]="textContent">
        </app-content-container>
    </div>
    <div class="l-calculator-container">
        <div class="l-sliders-container">
            <div class="l-slider-container">
                <div class="l-slider-name"> Number of Guests</div>
                <mat-slider min="10" max="10000" class="l-slider">
                    <input matSliderThumb [(ngModel)]="calculatorValues.numberOfGuests" (ngModelChange)="onEventPricingChange()">
                </mat-slider>
                <div class="l-slider-value form-control">
                    <input type="number" [(ngModel)]="calculatorValues.numberOfGuests" class="l-input-value" (ngModelChange)="onEventPricingChange()">
                </div>
            </div>
            <div class="l-slider-container">
                <div class="l-slider-name"> Average ticket price</div>
                <mat-slider min="0" max="1500" class="l-slider">
                    <input matSliderThumb [(ngModel)]="calculatorValues.averageTicketPrice" (ngModelChange)="onEventPricingChange()">
                </mat-slider>
                <div class="l-slider-value form-control">
                    <span>{{selectedCurrency.symbol}}</span> <input type="number" [(ngModel)]="calculatorValues.averageTicketPrice" class="l-input-value" (ngModelChange)="onEventPricingChange()">
                </div>
                
            </div>
            <div class="l-slider-container">
                <div class="l-slider-name"> Average order size</div>
                <mat-slider min="1" max="100" class="l-slider">
                    <input matSliderThumb [(ngModel)]="calculatorValues.averageOrderSize" (ngModelChange)="onEventPricingChange()">
                </mat-slider>
                <div class="l-slider-value form-control">
                    <input type="number" [(ngModel)]="calculatorValues.averageOrderSize" class="l-input-value text-center" (ngModelChange)="onEventPricingChange()"> <span>{{calculatorValues.averageOrderSize > 1 ? 'Tickets' : 'Ticket'}}</span>
                </div>
            </div>
        </div>

        <div class="l-fees-container">
            <div class="l-select-fees-container">
                <div class="l-payable-by">
                    <label>Commission fee</label>
                    <mat-select class="form-control" [(ngModel)]="orgCoversCommissionFee" (ngModelChange)="onEventPricingChange()">
                        <mat-option [value]="false">Guests will pay</mat-option>
                        <mat-option [value]="true">I will pay</mat-option>
                    </mat-select>
                </div>
                <div class="l-total-fees">
                    {{ totalCommissionFee | currency: selectedCurrency.symbol }} incl. VAT {{ orgCoversCommissionFee ? '' : 'per ticket'}}
                </div>
            </div>
            <div class="l-select-fees-container">
                <div class="l-payable-by">
                    <label>Booking fee</label>
                    <mat-select class="form-control" [(ngModel)]="orgCoversBookingFee"  (ngModelChange)="onEventPricingChange()">
                        <mat-option [value]="false">Guests will pay</mat-option>
                        <mat-option [value]="true">I will pay</mat-option>
                    </mat-select>
                </div>
                <div class="l-total-fees">
                    {{ totalBookingFee | currency: selectedCurrency.symbol }} {{ orgCoversBookingFee? '' : 'per order'}}
                </div>
            </div>
        </div>

        <div class="l-total-profits-container">
            <div class="l-guests-pay-per-order-container">
                <div class="l-guests-pay-per-order-text">
                    Guests pay per order
                </div>
                <div class="l-guests-pay-per-order-value">
                    <span>{{selectedCurrency.symbol}} {{ guestsPayPerOrder | currency: '' : ''}}</span>
                </div>
            </div>
            <div class="l-turnover-container">
                <div class="l-turnover-text">
                    Turnover
                </div>
                <div class="l-turnover-value">
                    <span>{{selectedCurrency.symbol}} {{calculatorValues.turnover | currency: '' : ''}}</span>
                </div>
            </div>
            <div class="l-profits-container">
                <div class="l-profit-text">
                    You'd Receive
                </div>
                <div class="l-profit-value">
                    <span>{{selectedCurrency.symbol}} {{calculatorValues.profit | currency: '' : '' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #mobileCalc>
    <div class="l-mobile-calculator" id="mobileCalc">
        <h2 class="l-heading mobile">Calculator</h2>
		<div class="l-input-mobile">
			<label class="l-mobile-label">Number of guests</label>
			<input type="number" [(ngModel)]="calculatorValues.numberOfGuests" (ngModelChange)="onEventPricingChange()" class="l-selector">
		</div>
		<div class="l-input-mobile">
			<label class="l-mobile-label">Average ticket price</label>
			<input type="number" [(ngModel)]="calculatorValues.averageTicketPrice" (ngModelChange)="onEventPricingChange()" class="l-selector">
		</div>
		<div class="l-input-mobile">
			<label class="l-mobile-label">Average order size</label>
			<input type="number" [(ngModel)]="calculatorValues.averageOrderSize" (ngModelChange)="onEventPricingChange()" class="l-selector">
		</div>
        <div class="l-input-mobile">
            <label class="l-mobile-label">Commission fee</label>
            <mat-select [(ngModel)]="orgCoversCommissionFee" (ngModelChange)="onEventPricingChange()" class="l-selector">
                <mat-option [value]="false">Guests will pay</mat-option>
                <mat-option [value]="true">I will pay</mat-option>
            </mat-select>
        </div>
        <div class="l-mobile-total-fees">
            {{ totalCommissionFee | currency: selectedCurrency.symbol }} incl. VAT {{ orgCoversCommissionFee ? '' : 'per ticket'}}
        </div>

        <div class="l-input-mobile">
            <label class="l-mobile-label">Booking fee</label>
            <mat-select [(ngModel)]="orgCoversBookingFee"  (ngModelChange)="onEventPricingChange()" class="l-selector">
                <mat-option [value]="false">Guests will pay</mat-option>
                <mat-option [value]="true">I will pay</mat-option>
            </mat-select>
        </div>
        <div class="l-mobile-total-fees">
            {{ totalBookingFee | currency: selectedCurrency.symbol }} {{ orgCoversBookingFee? '' : 'per order'}}
        </div>
        <div class="l-mobile-guests-pay-per-order-container">
            <div class="l-mobile-guests-pay-per-order-text">
                Guests pay per order
            </div>
            <div class="l-mobile-guests-pay-per-order-value">
                <span>{{selectedCurrency.symbol}} {{guestsPayPerOrder | currency: '' : ''}}</span>
            </div>
        </div>
        <div class="l-mobile-turnover-container">
            <div class="l-mobile-turnover-text">
                Turnover
            </div>
            <div class="l-mobile-turnover-value">
                <span>{{selectedCurrency.symbol}} {{calculatorValues.turnover | currency: '' : ''}}</span>
            </div>
        </div>
        <div class="l-mobile-profits-container">
            <div class="l-mobile-profit-text">
                You'd Receive
            </div>
            <div class="l-mobile-profit-value">
                <span>{{selectedCurrency.symbol}} {{calculatorValues.profit | currency: '' : '' }}</span>
            </div>
        </div>
    </div>
</ng-template>
