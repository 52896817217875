import { MockItem } from '@app/models/common.model';
// import { getReports } from '@app/utils/Fake';
// import { EventDashboardReports } from '@app/models/event-metadata.model';
import { HttpRequestMethod } from '@app/models/http.model';
// import { createDummyRegistrationUsers } from './mock-generators';

const mocks: MockItem[] = [
	// #region getPreviousVenues
	{
		api: 'getPreviousVenues',
		method: HttpRequestMethod.GET,
		url: /api\/product\/\d+\/previous_venues/g,
		payload: [
			{
				'venueId': '8ea7ed6a-d5da-45a4-ba99-4629f8757ae1',
				'venueName': 'Liz Hall Johannesburg',
				'venueAddress1': 'Liz Hall',
				'venueAddress2': '21 Robert Street',
				'venueLatitude': -26.239973,
				'venueLongitude': 28.055206,
				'city': 'Johannesburg',
				'country': 'SAR',
				'postalCode': '21900',
			}, {
				'venueId': '8ea7ed6a-d5da-45a4-ba99-4629f8757ae2',
				'venueName': 'Liz Concert Hall Johannesburg',
				'venueAddress1': 'Liz Concert Hall',
				'venueAddress2': '22 Jemmy Street',
				'venueLatitude': -26.239973,
				'venueLongitude': 28.055206,
				'city': 'Johannesburg',
				'country': 'SAR',
				'postalCode': '21900',
			}, {
				'venueId': 'cff91d15-ad3c-4fc2-99ac-beef9b5548e1',
				'venueName': 'Event Hall Cape Town',
				'venueAddress1': 'Event Hall',
				'venueLatitude': -33.925967,
				'venueLongitude': 18.41509099999996,
				'venueAddress2': '19 Jonh Street',
				'city': 'Cape Town',
				'country': 'SAR',
				'postalCode': '21800',
			}, {
				'venueId': 'cff91d15-ad3c-4fc2-99ac-beef9b5548e2',
				'venueName': 'Concert Hall Cape Town',
				'venueAddress1': 'Concert Hall',
				'venueAddress2': '20 Robert Street',
				'venueLatitude': -33.925967,
				'venueLongitude': 18.41509099999996,
				'city': 'Cape Town',
				'country': 'SAR',
				'postalCode': '21800',
			},
		],
	},
	// #endregion
	// #region getProfiles
	{
		api: 'createEvent',
		method: HttpRequestMethod.GET,
		url: /api\/product\/\d+\/new/g,
		payload: {
			productDetails: {
				name: null,
				description: null,
				startDate: null,
				endDate: null,
				isOneDayEvent: true,
				isOnline: false,
				id: 101160,
				seatsIOChartKey: null,
				maxCapacity: null,
				donationTarget: null,
				donationIncludeTickets: false,
				currencyId: 1,
				fbEventId: null,
				fbPicture: null,
				ticketStubDescription: null,
				useSeatNumbers: false,
				serviceFeeForHost: true,
				allowRefunds: false,
				showMap: false,
				serviceFee: 5.00,
				commission: 0.045,
				taxRateId: 3,
				public: null,
				ianaTimezone: 'Africa/Johannesburg',
				userId: 59888,
				hasVendors: false,
				hasSoldTickets: false,
				isEventFinished: false,
				categoryId: null,
				subCategoryId: null,
				formatId: null,
				allowNoCategories: null,
				bankAccountId: null,
				productType: 3,
				indefinite: false,
				imageString: null,
				originalImage: null,
				thumbnail: null,
				hasStreaming: false,
				hasOnlineContent: false,
			},
			organiser: null,
			schedules: null,
			venue: null,
			questions: [
				{
					id: 333437,
					question: 'First name',
					required: true,
					profileQuestionId: 1,
					enabled: true,
				},
				{
					id: 333438,
					question: 'Surname',
					required: true,
					profileQuestionId: 2,
					enabled: true,
				},
				{
					id: 333439,
					question: 'Email',
					required: true,
					profileQuestionId: 3,
					enabled: true,
				},
				{
					id: 333440,
					question: 'Cellphone',
					required: true,
					profileQuestionId: 6,
					enabled: true,
				},
			],
			currency: {
				ianaTimezone: 'Africa/Johannesburg',
				name: 'South African Rand',
				iso: 'ZAR',
				baseUtcOffset: '02:00:00',
				displayName: '(UTC+02:00) Harare, Pretoria',
				symbol: 'R',
				id: 1,
				domainId: 1,
			},
			tickets: null,
			seatingCategories: null,
			image: null,
			action: 'create',
			eventFeatureOptions: [
				{
					id: 1,
					name: 'Age restriction',
					options: [
						'7+',
						'12+',
						'15+',
						'18+',
						'family friendly',
						'adult only',
					],
					mandatory: true,
					featured: true,
					answer: null,
				},
				{
					id: 2,
					name: 'Video availability',
					options: [
						'1 day',
						'1 week',
						'2 weeks',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
				{
					id: 3,
					name: 'Test test',
					options: [
						'testy1',
						'i are test',
						'am i even a real test?',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
			],
			eventFeatureAnswers: null,
		},
	},
	{
		api: 'getEventDetails',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/details/g,
		payload: ({ id }) => ({
			productDetails: {
				name: 'Event',
				description: 'descriptions',
				startDate: '2021-12-13T09:00:00Z',
				endDate: '2022-01-17T10:00:00Z',
				isOneDayEvent: false,
				isOnline: false,
				id: 102222,
				seatsIOChartKey: null,
				maxCapacity: null,
				donationTarget: null,
				donationIncludeTickets: false,
				currencyId: 1,
				fbEventId: null,
				fbPicture: null,
				ticketStubDescription: null,
				useSeatNumbers: false,
				serviceFeeForHost: true,
				commissionForHost: false,
				allowRefunds: false,
				showMap: false,
				serviceFee: 5.00,
				commission: 0.045,
				taxRateId: 3,
				public: null,
				ianaTimezone: 'Africa/Johannesburg',
				userId: 59888,
				hasVendors: false,
				hasSoldTickets: false,
				isEventFinished: false,
				categoryId: null,
				subCategoryId: null,
				formatId: null,
				allowNoCategories: null,
				bankAccountId: null,
				productType: 1,
				indefinite: false,
				imageString: null,
				originalImage: null,
				thumbnail: null,
				hasStreaming: false,
				hasOnlineContent: false,
				eventEndDateChanged: false,
				fundraiserCertificate: null,
				pdfLogoUrl: '//images.quicket.co.za/default-pdf-logo_105_105.png',
			},
			organiser: {
				showWebsite: false,
				websiteUrl: null,
				name: 'Widdabif',
				email: 'elizabeth@quicket.co.za',
				telephone: '',
				showTelephone: false,
				mobile: '',
				showMobile: false,
				vatNumber: '',
				facebookUrl: '',
				twitterHandle: '',
				showTwitterFeed: false,
				twitterHashtag: null,
				id: 6,
				originalImage: null,
				imageString: null,
				thumbnail: null,
				croppedImage: null,
				spotifyLink: null,
				displayReviews: false,
				subscribers: null,
				description: null,
			},
			schedules: [
				/* {
					id: 11,
					hidden: false,
					hasInUseTickets: false,
					scheduleItems: [
						{
							id: 1,
							name: 'Schedule item 1',
							startDate: '2021-12-13T10:00:00Z',
							endDate: '2021-12-20T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
					],
				}, */
				{
					id: 12,
					hidden: false,
					hasInUseTickets: false,
					scheduleItems: [
						{
							id: 2,
							name: 'Schedule item 2',
							startDate: '2021-12-27T10:00:00Z',
							endDate: '2022-01-03T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
						{
							id: 3,
							name: 'Schedule item 3',
							startDate: '2022-01-10T10:00:00Z',
							endDate: '2022-01-17T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
						{
							id: 4,
							name: 'Schedule item 4',
							startDate: '2021-12-10T10:00:00Z',
							endDate: '2021-12-15T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
					],
				},
			],
			venue: null,
			questions: [
				{
					id: 333437,
					question: 'First name',
					required: true,
					profileQuestionId: 1,
					enabled: true,
				},
				{
					id: 333438,
					question: 'Surname',
					required: true,
					profileQuestionId: 2,
					enabled: true,
				},
				{
					id: 333439,
					question: 'Email',
					required: true,
					profileQuestionId: 3,
					enabled: true,
				},
				{
					id: 333440,
					question: 'Cellphone',
					required: true,
					profileQuestionId: 6,
					enabled: true,
				},
			],
			currency: {
				ianaTimezone: 'Africa/Johannesburg',
				name: 'South African Rand',
				iso: 'ZAR',
				baseUtcOffset: '02:00:00',
				displayName: '(UTC+02:00) Harare, Pretoria',
				symbol: 'R',
				id: 1,
				domainId: 1,
			},
			tickets: [
				{
					id: 11,
					name: 'Paid sub',
					description: null,
					price: null,
					sortOrder: 0,
					isTypeHidden: false,
					ticketStubInformation: null,
					minItemsCanSell: 1,
					maxItemsCanSell: 100,
					maxTicketsPerUser: 100,
					maxTicketsPerPurchase: 100,
					salesStart: '2021-02-10T12:50:44.2791341Z',
					salesEnd: '2076-12-31T22:00:00Z',
					scanningValidFrom: null,
					scanningValidTo: null,
					hideUntil: null,
					hideAfter: null,
					incrementOf: 0,
					donation: false,
					scannerColour: null,
					categories: [],
					questions: [],
					hasSales: false,
					atTheDoor: false,
					canVend: false,
					image: null,
					imageString: null,
					linkedToStream: false,
					benefits: [],
					recurringPeriod: 1,
				},
			],
			seatingCategories: null,
			image: null,
			action: 'create',
			eventFeatureOptions: [
				{
					id: 1,
					name: 'Age restriction',
					options: [
						'7+',
						'12+',
						'15+',
						'18+',
						'family friendly',
						'adult only',
					],
					mandatory: true,
					featured: true,
					answer: null,
				},
				{
					id: 2,
					name: 'Video availability',
					options: [
						'1 day',
						'1 week',
						'2 weeks',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
				{
					id: 3,
					name: 'Test test',
					options: [
						'testy1',
						'i are test',
						'am i even a real test?',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
			],
			eventFeatureAnswers: null,
		}),
	},
	{
		api: 'updateEventDetails',
		method: HttpRequestMethod.POST,
		url: /api\/product\/step\/2/g,
		payload: ({ id }) => ({
			productDetails: {
				name: 'Event',
				description: 'descriptions',
				startDate: '2021-12-13T09:00:00Z',
				endDate: '2022-01-17T10:00:00Z',
				isOneDayEvent: false,
				isOnline: false,
				id: 102222,
				seatsIOChartKey: null,
				maxCapacity: null,
				donationTarget: null,
				donationIncludeTickets: false,
				currencyId: 1,
				fbEventId: null,
				fbPicture: null,
				ticketStubDescription: null,
				useSeatNumbers: false,
				serviceFeeForHost: true,
				commissionForHost: false,
				allowRefunds: false,
				showMap: false,
				serviceFee: 5.00,
				commission: 0.045,
				taxRateId: 3,
				public: null,
				ianaTimezone: 'Africa/Johannesburg',
				userId: 59888,
				hasVendors: false,
				hasSoldTickets: false,
				isEventFinished: false,
				categoryId: null,
				subCategoryId: null,
				formatId: null,
				allowNoCategories: null,
				bankAccountId: null,
				productType: 1,
				indefinite: false,
				imageString: null,
				originalImage: null,
				thumbnail: null,
				hasStreaming: false,
				hasOnlineContent: false,
				eventEndDateChanged: false,
				fundraiserCertificate: null,
				pdfLogoUrl: '//images.quicket.co.za/default-pdf-logo_105_105.png',
			},
			organiser: {
				showWebsite: false,
				websiteUrl: null,
				name: 'Widdabif',
				email: 'elizabeth@quicket.co.za',
				telephone: '',
				showTelephone: false,
				mobile: '',
				showMobile: false,
				vatNumber: '',
				facebookUrl: '',
				twitterHandle: '',
				showTwitterFeed: false,
				twitterHashtag: null,
				id: 6,
				originalImage: null,
				imageString: null,
				thumbnail: null,
				croppedImage: null,
				spotifyLink: null,
				displayReviews: false,
				subscribers: null,
				description: null,
			},
			schedules: [
				{
					id: 12,
					hidden: false,
					hasInUseTickets: false,
					scheduleItems: [
						{
							id: 2,
							name: 'Schedule item 2',
							startDate: '2021-12-27T10:00:00Z',
							endDate: '2022-01-03T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
						{
							id: 3,
							name: 'Schedule item 3',
							startDate: '2022-01-10T10:00:00Z',
							endDate: '2022-01-17T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
						{
							id: 4,
							name: 'Schedule item 4',
							startDate: '2021-12-10T10:00:00Z',
							endDate: '2021-12-15T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},
					],
				},
				{
					id: 14,
					hidden: false,
					hasInUseTickets: false,
					scheduleItems: [
						{
							id: 2,
							name: 'Schedule item 1',
							startDate: '2022-03-15T10:00:00Z',
							endDate: '2022-03-20T12:00:00Z',
							hidden: false,
							hasInUseTickets: false,
						},

					],
				},
			],
			venue: null,
			questions: [
				{
					id: 333437,
					question: 'First name',
					required: true,
					profileQuestionId: 1,
					enabled: true,
				},
				{
					id: 333438,
					question: 'Surname',
					required: true,
					profileQuestionId: 2,
					enabled: true,
				},
				{
					id: 333439,
					question: 'Email',
					required: true,
					profileQuestionId: 3,
					enabled: true,
				},
				{
					id: 333440,
					question: 'Cellphone',
					required: true,
					profileQuestionId: 6,
					enabled: true,
				},
			],
			currency: {
				ianaTimezone: 'Africa/Johannesburg',
				name: 'South African Rand',
				iso: 'ZAR',
				baseUtcOffset: '02:00:00',
				displayName: '(UTC+02:00) Harare, Pretoria',
				symbol: 'R',
				id: 1,
				domainId: 1,
			},
			tickets: [
				{
					id: 11,
					name: 'Paid sub',
					description: null,
					price: null,
					sortOrder: 0,
					isTypeHidden: false,
					ticketStubInformation: null,
					minItemsCanSell: 1,
					maxItemsCanSell: 100,
					maxTicketsPerUser: 100,
					maxTicketsPerPurchase: 100,
					salesStart: '2021-02-10T12:50:44.2791341Z',
					salesEnd: '2076-12-31T22:00:00Z',
					scanningValidFrom: null,
					scanningValidTo: null,
					hideUntil: null,
					hideAfter: null,
					incrementOf: 0,
					donation: false,
					scannerColour: null,
					categories: [],
					questions: [],
					hasSales: false,
					atTheDoor: false,
					canVend: false,
					image: null,
					imageString: null,
					linkedToStream: false,
					benefits: [],
					recurringPeriod: 1,
				},
			],
			seatingCategories: null,
			image: null,
			action: 'create',
			eventFeatureOptions: [
				{
					id: 1,
					name: 'Age restriction',
					options: [
						'7+',
						'12+',
						'15+',
						'18+',
						'family friendly',
						'adult only',
					],
					mandatory: true,
					featured: true,
					answer: null,
				},
				{
					id: 2,
					name: 'Video availability',
					options: [
						'1 day',
						'1 week',
						'2 weeks',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
				{
					id: 3,
					name: 'Test test',
					options: [
						'testy1',
						'i are test',
						'am i even a real test?',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
			],
			eventFeatureAnswers: null,
		}),
	},
	{
		api: 'getEvent',
		method: HttpRequestMethod.GET,
		url: /api\/product\/\d+/g,
		payload: {
			productDetails: {
				name: 'Event',
				description: 'descriptions',
				startDate: null,
				endDate: null,
				isOneDayEvent: true,
				isOnline: false,
				id: 101175,
				seatsIOChartKey: null,
				maxCapacity: null,
				donationTarget: null,
				donationIncludeTickets: false,
				currencyId: 1,
				fbEventId: null,
				fbPicture: null,
				ticketStubDescription: null,
				useSeatNumbers: false,
				serviceFeeForHost: true,
				commissionForHost: false,
				allowRefunds: false,
				showMap: false,
				serviceFee: 5.00,
				commission: 0.045,
				taxRateId: 3,
				public: null,
				ianaTimezone: 'Africa/Johannesburg',
				userId: 59888,
				hasVendors: false,
				hasSoldTickets: false,
				isEventFinished: false,
				categoryId: null,
				subCategoryId: null,
				formatId: null,
				allowNoCategories: null,
				bankAccountId: null,
				productType: 3,
				indefinite: false,
				imageString: null,
				originalImage: null,
				thumbnail: null,
				hasStreaming: false,
				hasOnlineContent: false,
			},
			organiser: {
				showWebsite: false,
				websiteUrl: null,
				name: 'Widdabif',
				email: 'elizabeth@quicket.co.za',
				telephone: '',
				showTelephone: false,
				mobile: '',
				showMobile: false,
				vatNumber: '',
				facebookUrl: '',
				twitterHandle: '',
				showTwitterFeed: false,
				twitterHashtag: null,
				id: 6,
				originalImage: null,
				imageString: null,
				thumbnail: null,
				croppedImage: null,
				spotifyLink: null,
				displayReviews: false,
			},
			schedules: null,
			venue: null,
			questions: [
				{
					id: 333437,
					question: 'First name',
					required: true,
					profileQuestionId: 1,
					enabled: true,
				},
				{
					id: 333438,
					question: 'Surname',
					required: true,
					profileQuestionId: 2,
					enabled: true,
				},
				{
					id: 333439,
					question: 'Email',
					required: true,
					profileQuestionId: 3,
					enabled: true,
				},
				{
					id: 333440,
					question: 'Cellphone',
					required: true,
					profileQuestionId: 6,
					enabled: true,
				},
			],
			currency: {
				ianaTimezone: 'Africa/Johannesburg',
				name: 'South African Rand',
				iso: 'ZAR',
				baseUtcOffset: '02:00:00',
				displayName: '(UTC+02:00) Harare, Pretoria',
				symbol: 'R',
				id: 1,
				domainId: 1,
			},
			tickets: [
				{
					id: 11,
					name: 'Paid sub',
					description: null,
					price: null,
					sortOrder: 0,
					isTypeHidden: false,
					ticketStubInformation: null,
					minItemsCanSell: 1,
					maxItemsCanSell: 100,
					maxTicketsPerUser: 100,
					maxTicketsPerPurchase: 100,
					salesStart: '2021-02-10T12:50:44.2791341Z',
					salesEnd: '2076-12-31T22:00:00Z',
					scanningValidFrom: null,
					scanningValidTo: null,
					hideUntil: null,
					hideAfter: null,
					incrementOf: 0,
					donation: false,
					scannerColour: null,
					categories: [],
					questions: [],
					hasSales: false,
					atTheDoor: false,
					canVend: false,
					image: null,
					imageString: null,
					linkedToStream: false,
					benefits: [],
					recurringPeriod: 1,
				},
			],
			seatingCategories: null,
			image: null,
			action: 'create',
			eventFeatureOptions: [
				{
					id: 1,
					name: 'Age restriction',
					options: [
						'7+',
						'12+',
						'15+',
						'18+',
						'family friendly',
						'adult only',
					],
					mandatory: true,
					featured: true,
					answer: null,
				},
				{
					id: 2,
					name: 'Video availability',
					options: [
						'1 day',
						'1 week',
						'2 weeks',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
				{
					id: 3,
					name: 'Test test',
					options: [
						'testy1',
						'i are test',
						'am i even a real test?',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
			],
			eventFeatureAnswers: null,
		},
	},
	{
		api: 'updateEventOrganiser',
		method: HttpRequestMethod.POST,
		url: /api\/product\/step\/1/g,
		payload: {
			productDetails: {
				name: null,
				description: null,
				startDate: null,
				endDate: null,
				isOneDayEvent: true,
				isOnline: false,
				id: 101175,
				seatsIOChartKey: null,
				maxCapacity: null,
				donationTarget: null,
				donationIncludeTickets: false,
				currencyId: 1,
				fbEventId: null,
				fbPicture: null,
				ticketStubDescription: null,
				useSeatNumbers: false,
				serviceFeeForHost: true,
				allowRefunds: false,
				showMap: false,
				serviceFee: 5.00,
				commission: 0.045,
				taxRateId: 3,
				public: null,
				ianaTimezone: 'Africa/Johannesburg',
				userId: 59888,
				hasVendors: false,
				hasSoldTickets: false,
				isEventFinished: false,
				categoryId: null,
				subCategoryId: null,
				formatId: null,
				allowNoCategories: null,
				bankAccountId: null,
				productType: 3,
				indefinite: false,
				imageString: null,
				originalImage: null,
				thumbnail: null,
				hasStreaming: false,
				hasOnlineContent: false,
			},
			organiser: {
				showWebsite: false,
				websiteUrl: null,
				name: 'new profile',
				email: 'elizabeth@quicket.co.za',
				telephone: '',
				showTelephone: false,
				mobile: '',
				showMobile: false,
				vatNumber: '',
				facebookUrl: '',
				twitterHandle: '',
				showTwitterFeed: false,
				twitterHashtag: null,
				id: 6,
				originalImage: null,
				imageString: null,
				thumbnail: null,
				croppedImage: null,
				spotifyLink: null,
				displayReviews: false,
			},
			schedules: null,
			venue: null,
			questions: [
				{
					id: 333437,
					question: 'First name',
					required: true,
					profileQuestionId: 1,
					enabled: true,
				},
				{
					id: 333438,
					question: 'Surname',
					required: true,
					profileQuestionId: 2,
					enabled: true,
				},
				{
					id: 333439,
					question: 'Email',
					required: true,
					profileQuestionId: 3,
					enabled: true,
				},
				{
					id: 333440,
					question: 'Cellphone',
					required: true,
					profileQuestionId: 6,
					enabled: true,
				},
			],
			currency: {
				ianaTimezone: 'Africa/Johannesburg',
				name: 'South African Rand',
				iso: 'ZAR',
				baseUtcOffset: '02:00:00',
				displayName: '(UTC+02:00) Harare, Pretoria',
				symbol: 'R',
				id: 1,
				domainId: 1,
			},
			tickets: [],
			seatingCategories: null,
			image: null,
			action: 'create',
			eventFeatureOptions: [
				{
					id: 1,
					name: 'Age restriction',
					options: [
						'7+',
						'12+',
						'15+',
						'18+',
						'family friendly',
						'adult only',
					],
					mandatory: true,
					featured: true,
					answer: null,
				},
				{
					id: 2,
					name: 'Video availability',
					options: [
						'1 day',
						'1 week',
						'2 weeks',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
				{
					id: 3,
					name: 'Test test',
					options: [
						'testy1',
						'i are test',
						'am i even a real test?',
					],
					mandatory: false,
					featured: false,
					answer: null,
				},
			],
			eventFeatureAnswers: null,
		},
	},
	{
		api: 'getProfiles',
		method: HttpRequestMethod.GET,
		url: /api\/product\/\d+\/organisers/g,
		payload: [
			{
				showWebsite: false,
				websiteUrl: null,
				name: 'hello',
				email: 'elizabeth@quicket.co.za',
				telephone: '',
				showTelephone: false,
				mobile: '',
				showMobile: false,
				vatNumber: '',
				facebookUrl: '',
				twitterHandle: '',
				showTwitterFeed: false,
				twitterHashtag: null,
				id: 6,
				originalImage: null,
				imageString: null,
				thumbnail: null,
				croppedImage: null,
				spotifyLink: null,
				displayReviews: false,
			},
		],
	},
	{
		api: 'createProfile',
		method: HttpRequestMethod.POST,
		url: /api\/product\/\d+\/organisers/g,
		payload:
			{
				showWebsite: false,
				websiteUrl: null,
				name: 'new profile',
				email: 'elizabeth@quicket.co.za',
				telephone: '',
				showTelephone: false,
				mobile: '',
				showMobile: false,
				vatNumber: '',
				facebookUrl: '',
				twitterHandle: '',
				showTwitterFeed: false,
				twitterHashtag: null,
				id: 6,
				originalImage: null,
				imageString: null,
				thumbnail: null,
				croppedImage: null,
				spotifyLink: null,
				displayReviews: false,
			},
	},
	// #endregion
	{
		api: 'getTicketCategories',
		method: HttpRequestMethod.GET,
		url: /api\/product\/\d+\/seats\/chart\/\d+/g,
		payload: {
			'name': 'Super chart',
			'tablesLabelCounter': 3,
			'uuidCounter': 345,
			'categories': {
				'list': [{
					'label': 'Category 1',
					'color': '#3797AD',
					'key': 7,
				},
				{
					'label': 'Sample',
					'color': '#7D2147',
					'accessible': false,
					'key': 10,
				},
				{
					'label': 'Hello skype',
					'color': '#657808',
					'accessible': false,
					'key': 11,
				},
				{
					'label': 'New category 2',
					'color': '#288D65',
					'accessible': false,
					'key': 12,
				},
				{
					'label': 'New category',
					'color': '#534201',
					'accessible': false,
					'key': 13,
				},
				],
				'maxCategoryKey': 13,
			},
		},
	},
	{
		api: 'getCurrencies',
		method: HttpRequestMethod.GET,
		url: /api\/currencies/g,
		payload: [
			{
				timezoneId: '',
				name: '',
				iso: 'ZAR',
				baseUtcOffset: '',
				displayName: '',
				symbol: '',
				id: 1,
			}, {
				timezoneId: '',
				name: '',
				iso: 'USD',
				baseUtcOffset: '',
				displayName: '',
				symbol: '',
				id: 2,
			}, {
				timezoneId: '',
				name: '',
				iso: 'EUR',
				baseUtcOffset: '',
				displayName: '',
				symbol: '',
				id: 3,
			},
		],
	},
	{
		api: 'getEventMetadata',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/metadata/g,
		payload: ({ id }) => ({
			'name': 'name 9',
			'aclItems': [{
				'name': 'Dashboard',
				'systemName': 'dashboard',
				'url': '/account/events/manage/dashboard.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Tabbed Content',
				'systemName': 'tabbed_content',
				'url': '/account/events/manage/edit/content.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Tabbed Content Edit',
				'systemName': 'tabbed_content_edit',
				'url': '/account/events/manage/edit/editcontent.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Event details',
				'systemName': 'information',
				'url': '/account/events/manage/edit/edit.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Themes',
				'systemName': 'themes',
				'url': '/account/events/manage/edit/customize.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Design',
				'systemName': 'design',
				'url': '/account/events/manage/edit/design.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Social Media',
				'systemName': 'social_media',
				'url': '/account/events/manage/promote/socialmedia.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Share and Save',
				'systemName': 'share_and_save',
				'url': '/account/events/manage/promote/addeditdiscountcode.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Discount Codes',
				'systemName': 'discount_codes',
				'url': '/account/events/manage/promote/discountcodes.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Add/Edit Discount Code',
				'systemName': 'add_edit_discount_code',
				'url': '/account/events/manage/promote/addeditdiscountcode.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Get Discount Code List',
				'systemName': 'GetDiscountCodeList',
				'url': '/account/webservices/WCFAccount.svc/GetDiscountCodeList',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Link Campaigns',
				'systemName': 'link_campaigns',
				'url': '/account/events/manage/promote/linkcampaigns.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Promoters',
				'systemName': 'promoters',
				'url': '/account/events/manage/guests/promoters.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Allocate Tickets',
				'systemName': 'allocate_tickets',
				'url': '/account/events/manage/promote/allocatetickets.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Orders List',
				'systemName': 'orders_list',
				'url': '/account/events/manage/orders/default.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'GetOrders',
				'systemName': 'getorders',
				'url': '/api/events/id/orders',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'GetOrders2',
				'systemName': 'getorders2',
				'url': '/api/orders/id',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'CancelTickets',
				'systemName': 'canceltickets',
				'url': '/api/orders/id/cancel',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'ResendTickets',
				'systemName': 'resendtickets',
				'url': '/api/orders/id/resend',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'ChangeTicketTypes',
				'systemName': 'changetickettypes',
				'url': '/api/orders/id/changeTicketTypes',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Edit tickets',
				'systemName': 'edit_tickets',
				'url': '/account/tickets/edit.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Guest List',
				'systemName': 'guest_list',
				'url': '/account/events/manage/guests/guestlist.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Get Guest List',
				'systemName': 'get_guest_list',
				'url': '/account/webservices/WCFAccount.svc/GetGuestList',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Guestlist',
				'systemName': 'guestlist',
				'url': '/account/events/manage/guests/GetGuestlist.ashx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Get guest details',
				'systemName': 'get_guest_details',
				'url': '/account/webservices/WCFAccount.svc/GetTicketDetails',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Resend ticket details',
				'systemName': 'post_resend_ticket',
				'url': '/account/webservices/WCFAccount.svc/ResendTicket',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Complimentaries',
				'systemName': 'complimentaries',
				'url': '/account/events/manage/guests/complimentaries.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Delivery report for complimentaries',
				'systemName': 'delivery_report_for_complimentaries',
				'url': '/account/events/manage/guests/complimentarysendingreport.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'RSVPs',
				'systemName': 'invitations',
				'url': '/account/events/manage/guests/invitations.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'SMS Your Guests',
				'systemName': 'sms_your_guests',
				'url': '/account/events/manage/guests/smsyourguests.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Printed Tickets',
				'systemName': 'pre_printed_tickets',
				'url': '/account/events/manage/guests/preprintedtickets.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Print barcodes',
				'systemName': 'print_barcodes',
				'url': '/account/events/manage/guests/barcodeprinter.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Event Listing Summary',
				'systemName': 'event_listing_summary',
				'url': '/account/events/manage/edit/eventlistingsummary.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Visitors Report',
				'systemName': 'visitors_report',
				'url': '/account/events/manage/reports/visitorsreport.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Key Metrics',
				'systemName': 'GetAnalyticsKeyMetrics',
				'url': '/webservices/ReportingService.svc/GetAnalyticsKeyMetrics',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Referring Sources',
				'systemName': 'GetAnalyticsReferringSources',
				'url': '/webservices/ReportingService.svc/GetAnalyticsReferringSources',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Visitor Flow',
				'systemName': 'GetAnalyticsVisitorFlow',
				'url': '/webservices/ReportingService.svc/GetAnalyticsVisitorFlow',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics World Map',
				'systemName': 'GetAnalyticsWorldMap',
				'url': '/webservices/ReportingService.svc/GetAnalyticsWorldMap',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Country Map',
				'systemName': 'GetAnalyticsCountryMap',
				'url': '/webservices/ReportingService.svc/GetAnalyticsCountryMap',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Social Data',
				'systemName': 'GetAnalyticsSocialData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsSocialData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Mobile Data',
				'systemName': 'GetAnalyticsMobileData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsMobileData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Age Data',
				'systemName': 'GetAnalyticsAgeData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsAgeData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Gender Data',
				'systemName': 'GetAnalyticsGenderData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsGenderData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Sales Report',
				'systemName': 'sales_report',
				'url': '/account/events/manage/reports/salesreport.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Social Sales Data',
				'systemName': 'GetAnalyticsSocialSalesData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsSocialSalesData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Sales Ticket Types Data',
				'systemName': 'GetAnalyticsSalesTicketTypesData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsSalesTicketTypesData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Sales Referring Sources',
				'systemName': 'GetAnalyticsSalesReferringSources',
				'url': '/webservices/ReportingService.svc/GetAnalyticsSalesReferringSources',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Sales Key Metrics',
				'systemName': 'GetAnalyticsSalesKeyMetrics',
				'url': '/webservices/ReportingService.svc/GetAnalyticsSalesKeyMetrics',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Sales Flow',
				'systemName': 'GetAnalyticsSalesFlow',
				'url': '/webservices/ReportingService.svc/GetAnalyticsSalesFlow',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Mobile Sales Data',
				'systemName': 'GetAnalyticsMobileSalesData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsMobileSalesData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Checkin Report',
				'systemName': 'checkin_report',
				'url': '/account/events/manage/reports/checkinreport.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Key Checkin Metrics',
				'systemName': 'GetAnalyticsKeyCheckinMetrics',
				'url': '/webservices/ReportingService.svc/GetAnalyticsKeyCheckinMetrics',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Checkin Scanner Stations Data',
				'systemName': 'GetAnalyticsCheckinScannerStationsData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsCheckinScannerStationsData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Get Analytics Checkin Scanner Flow Data',
				'systemName': 'GetAnalyticsCheckinScannerFlowData',
				'url': '/webservices/ReportingService.svc/GetAnalyticsCheckinScannerFlowData',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Event Summary',
				'systemName': 'event_summary',
				'url': '/LiveContent/eventstatement.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Tax Invoice',
				'systemName': 'tax_invoice',
				'url': '/LiveContent/taxinvoice.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Financial Reports',
				'systemName': 'financial_reports',
				'url': 'NOURLNEEDED',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Ticket types',
				'systemName': 'tickets',
				'url': '/account/events/manage/edit/tickets.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Merchandise',
				'systemName': 'merchandise',
				'url': '/account/events/manage/merchandise/default.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Add Merchandise',
				'systemName': 'add_merchandise',
				'url': '/account/events/manage/merchandise/edititem.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Merchandise Edit',
				'systemName': 'merchandise_edit',
				'url': '/account/events/manage/merchandise/edititem.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Collect Info',
				'systemName': 'collect_info',
				'url': '/account/events/manage/edit/collectinformation.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Add/Edit Custom Question',
				'systemName': 'add_edit_custom_question',
				'url': '/account/events/manage/edit/addeditcustomquestion.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Registrations',
				'systemName': 'preregistrations',
				'url': '/account/events/manage/registrations/default.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Collect information on preregistration',
				'systemName': 'collect_information_on_preregistration',
				'url': '/account/events/manage/registrations/collectregistrationinformation.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Get Registrations',
				'systemName': 'get_registrations',
				'url': '/account/webservices/WCFAccount.svc/GetRegistrations',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Registrations View',
				'systemName': 'registration_view',
				'url': '/account/events/manage/registrations/view.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Payment Options',
				'systemName': 'payment_options',
				'url': '/account/events/manage/payment/paymentmethod.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Ticket groups',
				'systemName': 'ticket_groups',
				'url': '/account/events/manage/ticketgroups/default.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Ticket groups add/edit',
				'systemName': 'ticket_groups_add_edit',
				'url': '/account/events/manage/ticketgroups/addedit.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Teams',
				'systemName': 'teams',
				'url': '/account/events/manage/guests/teams.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Widget',
				'systemName': 'widget',
				'url': '/account/events/manage/promote/widget.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'WebHooks',
				'systemName': 'webhooks',
				'url': '/account/events/manage/integrate/webhooks.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				},
				{
					'actionName': 'Modify',
					'systemName': 'write',
				},
				],
			},
			{
				'name': 'Mobile Scanning Access',
				'systemName': 'scanning_access',
				'url': '1',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Scanners',
				'systemName': 'scanners',
				'url': '/account/events/manage/guests/scanners.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Add scanner to your event',
				'systemName': 'add_scanner_to_your_event',
				'url': '/account/events/manage/guests/addeditscanner.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Sell at the Door',
				'systemName': 'sell_at_the_door',
				'url': '1',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Get At The Door Tickets',
				'systemName': 'get_at_the_door_tickets',
				'url': '/webservices/AtTheDoor.svc/GetAtTheDoorTickets',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Push At The Door Tickets',
				'systemName': 'push_at_the_door_tickets',
				'url': '/webservices/AtTheDoor.svc/PushAtTheDoorTickets',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Vending',
				'systemName': 'api_vending',
				'url': '/api/orders',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Event settings',
				'systemName': 'settings',
				'url': '/account/events/manage/edit/settings.aspx',
				'aclActions': [{
					'actionName': 'Modify',
					'systemName': 'write',
				}],
			},
			{
				'name': 'Payout Details',
				'systemName': 'payout_details',
				'url': '/account/events/manage/edit/payoutdetails.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			{
				'name': 'Event page preview',
				'systemName': 'event_page_preview',
				'url': '/events/view.aspx',
				'aclActions': [{
					'actionName': 'Read-Only',
					'systemName': 'read',
				}],
			},
			],
			id,
			'accountingVersion': 2,
			'encrypt': '70A127418D1A8689FD74113268BC524941645A24A9E5558B884A75AC24EFE269C820FCDFDE7D9B0BE5E0713C82B0A73887C1F3E98B614BD0F7E4A1AA84DF2388', // eslint-disable-line max-len
			'isOwner': true,
			'creationStep': 10,
			'productStatus': 10,
			'isNewPageVersion': true,
			'refundFeeForOrganiser': false,
			'productType': 1,
			'hasStreaming': false,
			'currencyISO': 'ZAR',
			'email': 'elizabeth@quicket.co.za',
			'fullName': 'liz breslin',
			'phone': '021 424 9308',
			'userId': 59888,
			'organiserId': 6,
			'organiserName': 'Widabiff',
			'isOngoing': true,
			'directDistributionEnabled': true,
			'directDistributionId': 3335,
		}),
	},
	// {
	// 	api: 'getEventDashboardReports',
	// 	method: HttpRequestMethod.GET,
	// 	url: /api\/product\/(?<id>\d+)\/dashboarddata/g,
	// 	payload: ({ id }): EventDashboardReports => {
	// 		const reports = getReports(92);
	// 		return ({
	// 			totalEventIncome: 0,
	// 			totalTicketsSold: 0,
	// 			totalVisits: 0,
	// 			eventIncome: reports,
	// 			ticketTypesSold: reports,
	// 			pageVisits: [],
	// 			recentOrders: [],
	// 			conversionRate: 0,
	// 			averageRating: 0,
	// 			totalReviews: 0,
	// 		});
	// 	},
	// },
	{
		api: 'getEventTypes',
		method: HttpRequestMethod.GET,
		url: /api\/product\/categories/g,
		payload: {
			categories: [{
				id: 1,
				name: 'A',
				subcategories: [1, 2, 3],
			}, {
				id: 2,
				name: 'B',
				subcategories: [],
			}],
			eventsFormats: [{
				id: 1,
				name: 'F1',
			}, {
				id: 2,
				name: 'F2',
			}],
			subCategories: [{
				id: 1,
				name: 'sA',
			}, {
				id: 2,
				name: 'sB',
			}, {
				id: 3,
				name: 'sC',
			}],
		},
	},
	{
		api: 'sendInvites',
		method: HttpRequestMethod.POST,
		url: /api\/product\/\d+\/invites/g,
		payload: true,
	},
	{
		api: 'getFacebookIntegrationData',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/facebookdata/g,
		payload: ({ id }) => ({
			eventId: id,
			facebookEventId: null,
			name: 'Cool party',
			startDate: new Date(2019, 10, 11),
			endDate: new Date(2019, 10, 12),
			venue: {
				'id': 1018,
				'name': 'Rome Cir, Kansas City, MO 64153, USA',
				'addressLine1': 'Rome Circle',
				'addressLine2': null,
				'latitude': 39.2944986,
				'longitude': -94.7178636,
				'city': 'Kansas City',
				'country': 'United States',
				'province': 'Missouri',
				'postalCode': '64153',
				'longAddress': 'Rome Cir, Kansas City, MO 64153, USA',
				'mapZoom': 16,
			},
			isPublic: true,
			description: '123',
			image: {
				'itemId': id,
				'itemTypeId': 1,
				'userId': 1010,
				'dateAdded': '2019-03-18T18:36:10.084291Z',
				'mimeType': 'image/jpeg',
				'contentLength': 0,
				'url': '/content/images/events/upload/0007166_0.jpeg',
				'thumbUrl': '/content/images/events/upload/thumbs/0007166_105_105.jpeg',
				'id': 7166,
			},
		}),
	},
	{
		api: 'updateEventFacebookData',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/facebookdata/g,
		payload: ({ id }) => ({
			eventId: id,
			facebookEventId: 100000,
			name: 'Cool party',
			startDate: new Date(2019, 10, 11),
			endDate: new Date(2019, 10, 12),
			venue: {
				'id': 1018,
				'name': 'Rome Cir, Kansas City, MO 64153, USA',
				'addressLine1': 'Rome Circle',
				'addressLine2': null,
				'latitude': 39.2944986,
				'longitude': -94.7178636,
				'city': 'Kansas City',
				'country': 'United States',
				'province': 'Missouri',
				'postalCode': '64153',
				'longAddress': 'Rome Cir, Kansas City, MO 64153, USA',
				'mapZoom': 16,
			},
			isPublic: true,
			description: '123',
			image: {
				'itemId': id,
				'itemTypeId': 1,
				'userId': 1010,
				'dateAdded': '2019-03-18T18:36:10.084291Z',
				'mimeType': 'image/jpeg',
				'contentLength': 0,
				'url': '/content/images/events/upload/0007166_0.jpeg',
				'thumbUrl': '/content/images/events/upload/thumbs/0007166_105_105.jpeg',
				'id': 7166,
			},
		}),
	},
	{
		api: 'upsertEventTabbedContent',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/tabbedContent/g,
		payload: ({ id }) => ({
			id: 9,
			name: 'Tab 7 NEW',
			type: 3,
			isVisible: true,
			content: [{
				id: 1,
				name: 'Tab content 1',
				description: 'Description',
			}],
		}),
	},
	/* 	{
		api: 'upsertEventTabbedContent',
		method: HttpRequestMethod.PUT,
		url: /api\/product\/(?<id>\w+)\/tabbedContent/g,
		payload: ({ id }) => ({
			id: 1,
			name: 'Tab 1 updated',
			type: 3,
			isVisible: false,
			content: [{
				id: 1,
				name: 'Tab content 1',
				description: 'Description',
			}],
		}),
	}, */
	{
		api: 'getEventTabbedContent',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/tabbedContent/g,
		payload: ({ id }) => ([{
			id: 1,
			name: 'Tab 1',
			type: 3,
			isVisible: true,
			content: [{
				id: 1,
				name: 'Tab content 1',
				description: 'Description',
			}],
		}, {
			id: 2,
			name: 'Tab 2',
			type: 6,
			isVisible: true,
			content: [{
				id: 2,
				name: 'Tab content 2',
				description: 'Description',
				isVisible: true,
			}, {
				id: 3,
				name: 'Tab content 3',
				description: 'Description 3',
				isVisible: false,
			}],
		}, {
			id: 3,
			name: 'Tab 3',
			type: 4,
			isVisible: true,
			content: [{
				id: 4,
				name: 'Tab content 2',
				description: 'Description',
				isVisible: true,
			}, {
				id: 5,
				name: 'Tab content 3',
				description: 'Description 3',
				isVisible: true,
			}],
		}, {
			id: 4,
			name: 'Tab 4',
			type: 1,
			isVisible: true,
			content: [{
				id: 6,
				name: 'Tab content 2',
				description: 'Description',
				dateFrom: new Date(),
			}, {
				id: 7,
				name: 'Tab content 3',
				description: 'Description 3',
			}],
		}, {
			id: 6,
			name: 'Tab 5',
			type: 5,
			isVisible: true,
			content: [{
				id: 10,
				name: 'Tab content 2',
				description: 'Description',
				thumbnail: '/content/images/events/upload/thumbs/0011184_150_150.Png',
			}, {
				id: 11,
				name: 'Tab content 3',
				description: 'Description 3',
				thumbnail: '/content/images/events/upload/thumbs/0011184_150_150.Png',
			}],
		}, {
			id: 5,
			name: 'Tab 6',
			type: 2,
			isVisible: true,
			content: [{
				id: 8,
				name: 'Tab content 2',
				description: 'Description',
			}, {
				id: 9,
				name: 'Tab content 3',
				description: 'Description 3',
			}],
		}]),
	},
	{
		api: 'setTabContentVisibility',
		method: HttpRequestMethod.PUT,
		url: /api\/product\/(?<eventId>\w+)\/tabs\/(?<id>\w+)\/visibility/g,
		payload: ({ eventId, id }) => ({
			eventId,
			id,
			isVisible: false,
		}),
	},
	{
		api: 'reorderTabbedContent',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<eventId>\w+)\/tabbedContent\/reorder/g,
		payload: ({ eventId }) => ({
			eventId,
			order: [1, 2, 3, 4, 5, 6],
		}),
	},
	{
		api: 'deleteTabContent',
		method: HttpRequestMethod.DELETE,
		url: /api\/product\/(?<eventId>\w+)\/tabs\/(?<id>\w+)/g,
		payload: ({ eventId, id }) => ({
			eventId,
			id: Number(id),
		}),
	},
	{
		api: 'getEventTicketGroups',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/ticket-groups/g,
		payload: ({ id }) => ({
			ticketGroups: [
				{
					id: 192,
					name:  'Tickets',
					description: 'Description',
					sortOrder: 1,
					isImageShown: true,
					isExpanded: false,
					imageString: null,
					thumbnail: '/content/images/events/upload/thumbs/0012227_105_105.jpeg',
					linkedTicketTypes: [
						123,
						4562,
					],
				},
				{
					id: 182,
					name: 'Accommodation',
					description: 'Description',
					sortOrder: 1,
					isImageShown: true,
					isExpanded: false,
					imageString: null,
					thumbnail: null,
					linkedTicketTypes: [
						1223,
					],
				},
			],
			ticketTypes: [
				{
					id: 123,
					name: 'Adult ticket',
				},
				{
					id: 4562,
					name: 'Child ticket',
				},
				{
					id: 1223,
					name: 'Camping ticket',
				},
				{
					id: 6248,
					name: 'Tented ticket',
				},
			]}),
	},
	{
		api: 'deleteEventTicketGroup',
		method: HttpRequestMethod.DELETE,
		url: /api\/product\/(?<eventId>\w+)\/ticket-groups\/(?<id>\w+)/g,
		payload: ({ id, eventId }) => ({
			id: Number(id),
			eventId,
			deleted: true,
		}),
	},
	{
		api: 'getEventLinkCampaigns',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/linkcampaigns/g,
		payload: ({ id }) => ([{
			id: 1,
			name: 'Facebook campaign',
			eventLink: 'http://quicket.local/events/7848-payu/',
			campaignUrl: 'fbpost1',
			dateAdded: '5 July 2019 12:30',
			visits: 20,
			sales: 200,
			tickets: 5,
			currencyCode: 'ZAR',
		}]),
	},
	{
		api: 'deleteEventLinkCampaign',
		method: HttpRequestMethod.DELETE,
		url: /api\/product\/(?<eventId>\w+)\/linkcampaigns\/(?<id>\w+)/g,
		payload: ({ eventId, id }) => ({
			id: Number(id),
			eventId,
		}),
	},
	{
		api: 'addEventLinkCampaign',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/linkcampaigns/g,
		payload: ({ id }) => ({
			id: 2,
			name: 'Blog campaign',
			eventLink: 'http://quicket.local/events/7848-payu/',
			campaignUrl: 'blogpost1',
			dateAdded: '18 July 2019 10:15',
			visits: true,
			sales: 0,
			tickets: 0,
			currencyCode: 'ZAR',
		}),
	},
	{
		api: 'getEventDesign',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/design/g,
		payload: ({ id }) => ({
			id,
			imageString: null,
			color: '#c4d',
			originalImage: 'https://pbs.twimg.com/media/EALGVLVXkAACK9T?format=jpg&name=small',
			thumbnail: 'https://pbs.twimg.com/media/EALGVLVXkAACK9T?format=jpg&name=small',
		}),
	},
	{
		api: 'updateEventDesign',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/design/g,
		payload: ({ id }) => ({
			id,
			imageString: null,
			color: '#c45',
			originalImage: 'https://pbs.twimg.com/card_img/1151783367714705408/SAHwrhxF?format=jpg&name=small',
			thumbnail: 'https://pbs.twimg.com/card_img/1151783367714705408/SAHwrhxF?format=jpg&name=small',
		}),
	},
	{
		api: 'setTicketGroupUsage',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<eventId>\w+)\/ticket-groups-usage/g,
		payload: ({ eventId }) => ({
			eventId,
			useTicketGroups: true,
		}),
	},
	{
		api: 'upsertTicketGroup',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<eventId>\w+)\/ticket-groups/g,
		payload: ({ eventId }) => ({
			eventId,
			id: 193,
			name:  'Tickets (new)',
			description: 'Description',
			sortOrder: 1,
			isImageShown: true,
			isExpanded: true,
			imageString: null,
			thumbnail: '/content/images/events/upload/thumbs/0012227_105_105.jpeg',
			visible: true,
			linkedTicketTypes: [
				6248,
			],
		}),
	},
	{
		api: 'reorderTicketGroup',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<eventId>\w+)\/ticket-groups\/reorder/g,
		payload: ({ eventId }) => ({
			eventId,
			order: [182, 192],
		}),
	},
	{
		api: 'getEventDefinitions',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/definitions/g,
		payload: ({ id }) => ({
			id,
			event: 'event',
			events: 'events',
			ticket: 'ticket',
			tickets: 'tickets',
			buyer: 'buyer',
			register: 'You need to register to buy tickets for this event',
			usePromoCode: 'Use a promotion code',
			enterPromoCode: 'If you have a promotion code enter it here',
		}),
	},
	{
		api: 'updateEventDefinitions',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/definitions/g,
		payload: ({ id }) => ({
			id,
			event: 'trail run',
			events: 'trail runs',
			ticket: 'distance',
			tickets: 'distances',
			buyer: 'runner',
			register: 'You need to register to run in this race',
			usePromoCode: 'Use a promotion code',
			enterPromoCode: 'If you have a promotion code enter it here',
		}),
	},
	{
		api: 'getEventStreaming',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/streams/g,
		payload: ({ id }) => ({
			settings: {
				timeBeforeType: 'Now',
				timeBefore: null,
				notifyAttendees: false,
				showCollectionsOnStream: false,
				currencyCode: 'ZAR',
				includeChat: true,
				livestreamUrl: 'https://www.quicket.co.za/event/100047/0/livestream',
				showLivestreamCount: true,
				streamPageMessage: '',
				organiserCode: '',
				maxCodeUses: 2,
			},
			streams: [{
				streamId: 101,
				name: 'My Stream',
				enabled: true,
				type: 'Url',
				description: 'Thanks for joining my stream!',
				ticketLinks: [909],
				isGated: true,
				hideFrom: null,
				hideTo: '2020-09-16T10:12:00Z',
				// Provide a link
				url: 'https://quick.et',
				embed: true,
				instructions: null,
				displayDirectLink: true,
				linkUknown: false,
				// Quicket Hosted Streaming
				publishLogin: null,
				publishName: null,
				publishPassword: null,
				publishUrl: null,
				convertStreamToVideo: false,
				// Upload video file
				uploadId: null,
				thumbnailUrl: null,
			}],
			ticketTypes: [
				{
					id: 909,
					name: 'Ticket 1',
				},
				{
					id: 101,
					name: 'Ticket 2',
				},
			],
			eventHasBegun: true,
		}),
	},
	{
		api: 'upsertEventStreamingRoom',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/streams/g,
		payload: ({ id }) => ({
			id: 302,
			name: 'My New Stream',
			enabled: true,
			type: 'Mux',
			description: 'Another awesome stream',
			ticketLinks: [101],
			isGated: true,
			// Provide a link
			url: null,
			embed: true,
			instructions: null,
			displayDirectLink: true,
			// Quicket Hosted Streaming
			publishLogin: null,
			publishName: 'muxupload',
			publishPassword: null,
			publishUrl: 'mux.url',
			convertStreamToVideo: false,
			// Upload video file
			uploadId: null,
			thumbnailUrl: null,

		}),
	},
	{
		api: 'reorderStreams',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<eventId>\w+)\/streams\/sortorder/g,
		payload: ({ eventId }) => ({
			eventId,
			order: [101, 302],
		}),
	},
	{
		api: 'deleteEventStreamingRoom',
		method: HttpRequestMethod.DELETE,
		url: /api\/product\/(?<eventId>\w+)\/streams\/(?<id>\w+)/g,
		payload: ({ eventId, id }) => ({
			eventId,
			id: Number(id),
		}),
	},
	{
		api: 'setEventStreamingEnabled',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<eventId>\w+)\/streams\/(?<id>\w+)\/enabled/g,
		payload: ({ eventId, id }) => ({
			eventId,
			id,
			isEnabled: false,
		}),
	},
	{
		api: 'setEventStreamingSettings',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/streams\/settings/g,
		payload: ({ eventId }) => ({
			timeBeforeType: 'Minutes',
			timeBefore: 10,
			notifyAttendees: true,
			showCollectionsOnStream: false,
			currencyCode: 'ZAR',
			includeChat: true,
			liveStreamUrl: 'quicket.co.za/stream',
		}),
	},
	{
		api: 'setOrganiserPreviewCode',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/streams\/universalaccesscode/g,
		payload: ({ eventId }) => ({
			timeBeforeType: 'Minutes',
			timeBefore: 10,
			notifyAttendees: true,
			showCollectionsOnStream: false,
			currencyCode: 'ZAR',
			includeChat: true,
			liveStreamUrl: 'quicket.co.za/stream',
			showLivestreamCount: true,
			videoStreamingCost: 100,
			videoEncodingCost: 2,
			videoStorageCost: 5,
			streamPageMessage: 'We are delayed 5 minutes',
			organiserCode: 'F5049',
		}),
	},
	{
		api: 'deleteOrganiserPreviewCode',
		method: HttpRequestMethod.DELETE,
		url: /api\/product\/(?<id>\w+)\/streams\/universalaccesscode/g,
		payload: ({ eventId }) => ({
			timeBeforeType: 'Minutes',
			timeBefore: 10,
			notifyAttendees: true,
			showCollectionsOnStream: false,
			currencyCode: 'ZAR',
			includeChat: true,
			liveStreamUrl: 'quicket.co.za/stream',
			showLivestreamCount: true,
			videoStreamingCost: 100,
			videoEncodingCost: 2,
			videoStorageCost: 5,
			streamPageMessage: 'We are delayed 5 minutes',
			organiserCode: null,
		}),
	},
	{
		api: 'getEventCollections',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/collections/g,
		payload: ({ id }) => ({
			id,
			enabled: false,
			title: null,
			description: null,
			hasStreamingEnabled: true,
			showOnStreamingPage: false,
			buttonText: 'DONATE',
		}),
	},
	{
		api: 'updateEventCollections',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/collections/g,
		payload: ({ id }) => ({
			id,
			enabled: true,
			title: 'Donations for me please',
			description: 'Give me money for things',
			hasStreamingEnabled: true,
			showOnStreamingPage: true,
			buttonText: 'PLEASE DONATE',
		}),
	},
	{
		api: 'getWidget',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/widget/g,
		payload: ({ id }) => ({
			id,
			widgetCode: '<iframe src="https://www.quicket.co.za/embed.aspx?productid=109608&productname=it-was-all-a-stream-i-used-to-read-word-up-magazine&embed=true&v=2" frameborder="0" scrolling="yes" width="100%" height="600"></iframe>', // eslint-disable-line max-len
		}),
	},
	{
		api: 'getTicketSettings',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/tickets\/settings/g,
		payload: ({ id }) => ({
			id,
			customMessage: null,
			image: {
				'itemId': id,
				'itemTypeId': 1,
				'userId': 59888,
				'dateAdded': '2019-03-18T18:36:10.084291Z',
				'mimeType': 'image/jpeg',
				'contentLength': 0,
				'url': '/content/images/events/upload/0184444_0.jpeg',
				'thumbUrl': '/content/images/events/upload/thumbs/0184444_105_105.jpeg',
				'id': 184444,
			},
			ticketStubDescription: 'Doors open at 7pm',
			includePDF: true,
			useCustom: false,
			includeMessage: false,
			ticketPreviewLink: 'https://www.quicket.com/livecontent/tickets.aspx?x=522483&px=830CF91D988C48E99B9014638FEFA0ED9C1B558DC563451493A9CD51C66349A4&eventid=137064', // eslint-disable-line max-len
		}),
	},
	{
		api: 'getEventScheduleTransfers',
		method: HttpRequestMethod.GET,
		url: /api\/product\/(?<id>\w+)\/transferpurchaseschedule/g,
		payload: ({ id }) => ({
			scheduleItems: [{
				id: 1,
				dateFrom: '2021-07-22T20:00:00Z',
				dateTo: '2021-07-22T21:00:00Z',
				validTicketCount: 3,
				dateHasPassed: false,
			},
			{
				id: 2,
				dateFrom: '2021-07-29T20:00:00Z',
				dateTo: '2021-07-29T21:00:00Z',
				validTicketCount: 0,
				dateHasPassed: false,
			},
			{
				id: 3,
				dateFrom: '2021-08-05T20:00:00Z',
				dateTo: '2021-08-05T21:00:00Z',
				validTicketCount: 10,
				dateHasPassed: false,
			}],
			settings: {
				moveFromId: null,
				moveToId: null,
				isSeated: false,
				reason: null,
			},
		}),
	},
	{
		api: 'updateEventScheduleTransfers',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/transferpurchaseschedule/g,
		payload: ({ id }) => ({
			scheduleItems: [{
				id: 1,
				dateFrom: '2021-07-22T20:00:00Z',
				dateTo: '2021-07-22T21:00:00Z',
				validTicketCount: 0,
				dateHasPassed: false,
			},
			{
				id: 2,
				dateFrom: '2021-07-29T20:00:00Z',
				dateTo: '2021-07-29T21:00:00Z',
				validTicketCount: 0,
				dateHasPassed: false,
			},
			{
				id: 3,
				dateFrom: '2021-08-05T20:00:00Z',
				dateTo: '2021-08-05T21:00:00Z',
				validTicketCount: 13,
				dateHasPassed: false,
			}],
			settings: {
				moveFromId: 1,
				moveToId: 3,
				isSeated: false,
				reason: 'New covid restrictions',
			},
		}),
	},
	{
		api: 'setEventCovidCompliant',
		method: HttpRequestMethod.POST,
		url: /api\/product\/(?<id>\w+)\/togglecovid/g,
		payload: ({ id }) => ({
			covidComplianceEnabled: {
				covidComplianceEnabled: true,
				entryRequirements: ['Antibody test', 'Vaccine certificate'],
			},
		}),
	},
	// {
	// 	api: 'getEventRegistrationsResults',
	// 	method: HttpRequestMethod.GET,
	// tslint:disable-next-line: max-line-length
	// 	url: /api\/events\/(?<id>\w+)\/registrations?(?=.*pageSize=(?<pageSize>\d+|null))
	// (?=.*pageNumber=(?<pageNumber>\d+|null))(?=.*search=(?<search>[^&]*|null)).*/g,
	// 	payload: ({ search }) => {
	// 		const amount = Math.floor(Math.random() * 20);
	// 		const registeredUsers = createDummyRegistrationUsers(amount, search !== 'null' ? search : null);
	// 		return registeredUsers;
	// 	},
	// },
];

export default mocks;
