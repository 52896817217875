<div
	[ngClass]="{'g-white': isWhite}"
	class="l-contact-container"
>
	<i
		[ngClass]="{'g-white': isWhite}"
		class="l-icon fas fa-phone"
	></i>
	<div class="">{{ phone }} </div>
</div>
<div
	[ngClass]="{'g-white': isWhite}"
	class=" l-contact-container"
>
	<i
		[ngClass]="{'g-white': isWhite}"
		class="l-icon fas fa-envelope"
	></i>
	{{ email }}
</div>
<div
	[ngClass]="{'g-white': isWhite}"
	class=" l-contact-container"
>
	© Copyright {{ year }} Quicket
</div>