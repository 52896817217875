import { Component } from '@angular/core';
import { SectionContent, Type } from '../../../elements/models/element.model';
import { URLCreator } from '@services/url/url.dictionary';

@Component({
	selector: 'app-on-the-day-section',
	templateUrl: './on-the-day-section.component.html',
	styleUrls: ['./on-the-day-section.component.sass'],
})
export class OnTheDaySectionComponent {
	imageSource = '/app-elements/assets/Ticketing_Execute.png';
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'EXECUTE',
		headingText: 'On The Day',
		contentText: `We offer a range of additional services including scanning equipment, 
		on-site ticket sales, YOCO contactless payments, 
		and access to our world-class field service team.`,
		buttonType: Type.PRIMARY,
		buttonText: 'CREATE EVENT',
		buttonLink: URLCreator.createEvent(),
	};
}
