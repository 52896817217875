import { Component } from '@angular/core';
import { CardType, FeatureCard } from '../../models/element.model';
import { TESTIMONIALS } from '../../utils/consts';

@Component({
	selector: 'app-testimonials-section',
	templateUrl: './testimonials-section.component.html',
	styleUrls: ['./testimonials-section.component.sass'],
})
export class TestimonialsSectionComponent {
	cards: FeatureCard[] = TESTIMONIALS;
	type = CardType.TESTIMONIAL;
}
