<mat-calendar
	#calendar
	[(selected)]="selectedDateRange"
	(selectedChange)="rangeChanged($event)"
></mat-calendar>
<div class="l-button-container">
	<button
		class="g-tertiary-button l-clear"
		(click)="handleClearDates()"
		[disabled]="!selectedDateRange?.start"
		[ngClass]="{ disabled: !selectedDateRange?.start }"
	>CLEAR DATES</button>
	<button
		class="g-primary-button l-save"
		(click)="handleApplyDates()"
		[disabled]="!selectedDateRange?.end || !selectedDateRange?.start"
		[ngClass]="{ disabled: (!selectedDateRange?.end || !selectedDateRange?.start) }"
	>SAVE</button>
</div>