import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { URLCreator } from '@app/services/url/url.dictionary';
import { map, Subject, takeUntil } from 'rxjs';
import { Pricing, Currency } from '../../../elements/models/currencies.model';
import { SectionContent, Type } from '../../../elements/models/element.model';

@Component({
	selector: 'app-fees-percentage-card',
	templateUrl: './fees-percentage-card.component.html',
	styleUrls: ['./fees-percentage-card.component.sass'],
})
export class FeesPercentageCardComponent implements OnInit{
	@Input() selectedCurrency: Currency;
	@Input() pricing: Pricing;
	isMobile = false;
	isSmall = false;
	private destroyed$ = new Subject<void>();
	contactUs = URLCreator.contactUs();

	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'TICKET SALES CALCULATOR',
		headingText: 'Ticket Sales',
		contentText:
			`Quicket offers two fees: commission and booking fees. 
			Typically, event organisers pay the commission, and ticket buyers pay the booking fees. 
			However, flexible pricing allows you to choose who pays which fee.`,
	};

	constructor(private breakpointObserver: BreakpointObserver) {}

	ngOnInit() {
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});
	}
}
