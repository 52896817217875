import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-quicket-socials',
	templateUrl: './quicket-socials.component.html',
	styleUrls: ['./quicket-socials.component.sass'],
})
export class QuicketSocialsComponent {
	@Input() facebook = 'https://www.facebook.com/203195039742896';
	@Input() twitter = 'https://twitter.com/QuicketSA';
	@Input() instagram = 'https://www.instagram.com/quicket';
	@Input() linkedin = 'http://www.linkedin.com/company/2350818';
	@Input() isWhite = false;
	@Input() customStyle = 'l-social-button';
}
