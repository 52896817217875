import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureCard } from '@app/elements/models/element.model';

@Component({
	selector: 'app-testimonial-modal',
	templateUrl: './testimonial-modal.component.html',
	styleUrls: ['./testimonial-modal.component.sass'],
})
export class TestimonialModalComponent {

	constructor(
		public dialogRef: MatDialogRef<TestimonialModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			card: FeatureCard;
		}) { }

	handleCancel() {
		this.dialogRef.close();
	}
}
