import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Subject, takeUntil } from 'rxjs';
import { FeatureCard } from '../../models/element.model';
import { TestimonialModalComponent } from '../testimonial-modal/testimonial-modal.component';

@Component({
	selector: 'app-testimonial-card',
	templateUrl: './testimonial-card.component.html',
	styleUrls: ['./testimonial-card.component.sass'],
})
export class TestimonialCardComponent implements OnInit {
	@Input() card: FeatureCard;
	isMobile = false;
	isSmall = false;
	private destroyed$ = new Subject<void>();
	public textToShow = '';
	seeMore = false;
	constructor (
		private dialog: MatDialog,
		private breakpointObserver: BreakpointObserver
	) {}

	ngOnInit(): void {
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});

		if (this.card.text.length >= 128) {
			this.textToShow = this.card.text.substring(0, 128);
			this.seeMore = true;
		} else {
			this.textToShow = this.card.text;
		}
	}

	openTestimonialModal() {
		this.dialog.open(TestimonialModalComponent, {
			data: {
				card: this.card,
			},
			panelClass: 'g-testimonial-dialog',
		});
	}
}
