import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, take } from 'rxjs';
import { DataService } from '../../services/data.service';
import { Currency } from '../../models/currencies.model';

@Component({
	selector: 'app-ticket-sales-estimate',
	templateUrl: './ticket-sales-estimate.component.html',
	styleUrls: ['./ticket-sales-estimate.component.sass'],
})
export class TicketSalesEstimateComponent implements OnInit, OnDestroy {

	currencies: Currency[];
	selectedCurrency: Currency;
	private destroyed$ = new Subject<void>();

	constructor (
		private dataService: DataService
	) {}

	ngOnInit(): void {
		this.dataService.getCurrencyData().pipe(take(1)).subscribe(currencies => {
			this.currencies = currencies.payload;
			if (this.currencies) {
				this.getSelectedCurrency();
			}
		});
	}

	getSelectedCurrency() {
		this.currencies.forEach(currency => {
			if (currency.isDefaultForDomain) {
				this.selectedCurrency = currency;
			}
		});
	}

	updateSelectedCurrency(currency: Currency) {
		this.selectedCurrency = currency;
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
