import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CardType, FeatureCard } from '../../models/element.model';

@Component({
	selector: 'app-horizontal-carousel',
	templateUrl: './horizontal-carousel.component.html',
	styleUrls: ['./horizontal-carousel.component.sass'],
	encapsulation: ViewEncapsulation.None,
})
export class HorizontalCarouselComponent {
	@Input() cards: FeatureCard[];
	@Input() type: CardType;

	cardType = CardType;
}
