import { MockItem } from '@app/models/common.model';
import { HttpRequestMethod } from '@app/models/http.model';
import { createDummyCampaign, createDummyEventCards, generateChartData, getMailAccounts } from './mock-generators';

const mocks: MockItem[] = [
	{
		api: 'getSalesOverview',
		method: HttpRequestMethod.GET,
		url: /api\/product\/getSalesOverview/g,
		payload: {
			totalOrders: '1467',
			orders: [
				generateChartData('Tickets'),
			],
			totalSales: 'R100 000',
			sales: [
				generateChartData('Quicket'),
				generateChartData('Direct'),
			],
		},
	},
	{
		api: 'getCurrentCampaigns',
		method: HttpRequestMethod.GET,
		url: /api\/product\/getCurrentCampaigns/g,
		payload: createDummyCampaign(3),
	},
	// {
	// 	api: 'getPastEvents',
	// 	method: HttpRequestMethod.GET,
	// 	url: /api\/product\/getPastEvents\/(?<amount>\w+)/g,
	// 	payload: ({ amount }) => (
	// 		createDummyEventCards(amount)
	// 	),
	// },
	// {
	// 	api: 'getUpcomingEvents',
	// 	method: HttpRequestMethod.GET,
	// 	url: /api\/product\/getUpcomingEvents\/(?<amount>\w+)/g,
	// 	payload: ({ amount }) => (
	// 		createDummyEventCards(amount)
	// 	),
	// },
	{
		api: 'getFilteredProducts',
		method: HttpRequestMethod.GET,
		url: /api\/product\/filteredProducts\?(?=.*type=(?<type>1|2|3|null))(?=.*pageSize=(?<pageSize>\d+|null))(?=.*pageNumber=(?<pageNumber>\d+|null))(?=.*search=(?<search>[^&]*|null))(?=.*sortOrder=(?<sortOrder>0|1|null))(?=.*sortBy=(?<sortBy>1|2|3|null))(?=.*statusFilter=(?<statusFilter>1|10|20|30|40|50|null))(?=.*organiserFilter=(?<organiserFilter>\d+|null)).*/g, // eslint-disable-line max-len
		payload: ({ statusFilter, organiserFilter, search }) => {
			const amount = Math.floor(Math.random() * 20);
			return {
				products: createDummyEventCards(
					amount,
					statusFilter !== 'null' ? statusFilter : null,
					organiserFilter !== 'null' ? organiserFilter : null,
					search !== 'null' ? search : null),
				scope: amount,
			};
		},
	},
	// {
	// 	api: 'getProductsInfo',
	// 	method: HttpRequestMethod.GET,
	// 	url: /api\/product\/productsInfo/g,
	// 	payload: getProductsInfo(5, 10, 5),
	// },
	{
		api: 'getMailAccountsByUserId',
		method: HttpRequestMethod.GET,
		url: /api\/users\/(?<id>\w+)\/mailaccounts/g,
		payload: () => getMailAccounts(20),
	},

];

export default mocks;
