import { EventLinkCampaign, ProductStatus, EventRegistration } from '@app/models/event.model';
import { BasicEventCard, MailAccount } from '@app/models/organiser.model';
import { ProductDetails } from '@app/models/product.model';

const organisersNames = [
	'Stellar Events',
	'Blue Horizon Conferences',
	'Apex Event Management',
	'Nova Gatherings',
	'Fusion Event Planners',
	'Zenith Event Coordinators',
	'Eclipse Event Solutions',
	'Spectrum Event Organizers',
	'Summit Event Group',
	'Catalyst Event Consultants',
	'Mirage Event Architects',
	'Vortex Event Creators',
	'Infinity Event Designers',
	'Oasis Event Experts',
	'Pinnacle Event Strategies',
];

const Names = [
	'Michael Jones',
	'James Smith',
	'Joseph Davis',
	'Susan Garcia',
	'Richard Brown',
	'Susan Johnson',
	'Patricia Williams',
	'William Lopez',
	'Richard Smith',
	'Susan Johnson',
	'Patricia Johnson',
	'Joseph Brown',
	'Susan Gonzalez',
	'David Anderson',
	'Susan Rodriguez',
];

const Emails = [
	'michael.jones@example.com',
	'james.smith@mailtest.com',
	'joseph.davis@mailtest.com',
	'susan.garcia@dummy.org',
	'richard.brown@mailtest.com',
	'susan.johnson@example.com',
	'patricia.williams@sample.net',
	'william.lopez@example.com',
	'richard.smith@example.com',
	'susan.johnson@example.com',
	'patricia.johnson@mailtest.com',
	'joseph.brown@mailtest.com',
	'susan.gonzalez@sample.net',
	'david.anderson@dummy.org',
	'susan.rodriguez@mailtest.com',
];


const eventNames = [
	'Global Tech Summit',
	'Future Leaders Conference',
	'Eco Innovation Forum',
	'Digital Media Expo',
	'Health & Wellness Retreat',
	'Artisan Food Festival',
	'International Film Showcase',
	'Renewable Energy Symposium',
	'Global Education Congress',
	'Adventure Sports Carnival',
	'Luxury Travel Expo',
	'Creative Arts Workshop',
	'Urban Design Summit',
	'Culinary Arts Gala',
	'Space Exploration Symposium',
	'Virtual Reality Conference',
	'Sustainable Living Fair',
	'Cybersecurity Summit',
	'Music Producers Meetup',
	'Fashion Week Extravaganza',
	'Robotics Challenge Event',
	'Global Finance Forum',
	'AI & Machine Learning Summit',
	'International Yoga Day Festival',
	'Entrepreneurship Bootcamp',
	'Wildlife Conservation Conference',
	'Vintage Car Rally',
	'World History Convention',
	'Science Fiction Writers Conclave',
	'Oceanography Research Symposium',
];

export function generateRandomString(length: number): string {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return result;
}

export function generateRandomNumber(length: number): number {
	const characters = '0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return +result;
}

export function randomProductStatus() {
	const productTypes = [1, 10, 20, 30, 40, 50];
	return productTypes[Math.floor(Math.random() * productTypes.length)];

}

export function randomRegistrationStatus() {
	const registrationStatus = [1, 2, 3, 4];
	return registrationStatus[Math.floor(Math.random() * registrationStatus.length)];
}

export function createDummyCampaign(amount: number): EventLinkCampaign[] {
	const dummyCampaign = [];
	for (let i = 0; i < amount; i++) {
		dummyCampaign.push({
			id: i,
			name: eventNames[Math.floor(Math.random() * eventNames.length)],
			campaignUrl: generateRandomString(15),
			eventLink: 'www.' + generateRandomString(10) + '.com',
			dateAdded: new Date().toISOString(),
			visits: generateRandomNumber(3),
			sales: generateRandomNumber(3),
			tickets: generateRandomNumber(3),
			currencyCode: 'ZAR',
		});
	}
	return dummyCampaign;
}

export function createDummyEventCards(
	amount: number,
	statusFilter: ProductStatus = null,
	organiserFilter: number = null,
	search: string = null
): BasicEventCard[] {
	let dummyEventCards = [];

	for (let i = 0; i < amount; i++) {
		dummyEventCards.push({
			id: i,
			organiserName: organiserFilter ? organisersNames[0] : organisersNames[Math.floor(Math.random() * organisersNames.length)],
			productName: eventNames[Math.floor(Math.random() * eventNames.length)],
			startDate: new Date().toISOString(),
			isPublic: Math.random() > 0.5,
			productStatus: statusFilter ? statusFilter : randomProductStatus(),
			quantity: generateRandomNumber(2),
			productImage: `https://dummyimage.com/600x400/000/fff&text=Original+Image+${i}`,
			isOngoing: Math.random() > 0.5,
			isOwner: Math.random() > 0.5,
		});
	}

	if (search) {
		const lowerCaseSearchTerm = search.toLowerCase();
		dummyEventCards = dummyEventCards.filter(eventCard =>
			eventCard.productName.toLowerCase().includes(lowerCaseSearchTerm)
		);
	}

	return dummyEventCards;
}

export function getProductsInfo(organisers: number, events: number, fundraisers: number): any {
	const organiserArray = [];
	const eventNamesArray = [];
	const fundraiserArray = [];
	for (let i = 0; i < organisers; i++) {
		organiserArray.push({
			id: +(i.toString().concat(generateRandomNumber(3).toString())),
			name: organisersNames[Math.floor(Math.random() * organisersNames.length)],

		});
	}
	for (let j = 0; j < events; j++) {
		eventNamesArray.push({
			id: +(j.toString().concat(generateRandomNumber(3).toString())),
			name: eventNames[Math.floor(Math.random() * eventNames.length)],

		});
	}
	for (let k = 0; k < fundraisers; k++) {
		fundraiserArray.push({
			id: +(k.toString().concat(generateRandomNumber(3).toString())),
			name: eventNames[Math.floor(Math.random() * eventNames.length)],
		});
	}

	return { organisers: organiserArray, events: eventNamesArray, fundraisers: fundraiserArray };
}

export function generateDummyProductDetails(id: number): ProductDetails {
	return {
		name: `Event Name ${id}`,
		description: `Description of Event ${id}`,
		startDate: new Date().toISOString(),
		endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString(),
		isOneDayEvent: Math.random() > 0.5,
		isOnline: Math.random() > 0.5,
		id: id,
		seatsIOChartKey: generateRandomString(10),
		maxCapacity: Math.floor(Math.random() * 1000),
		maxTicketsPerOrder: Math.floor(Math.random() * 10),
		donationTarget: Math.floor(Math.random() * 5000),
		fbEventId: generateRandomString(15),
		fbPicture: `https://dummyimage.com/600x400/000/fff&text=Event+${id}`,
		useSeatNumbers: Math.random() > 0.5,
		allowRefunds: Math.random() > 0.5,
		serviceFeeForHost: Math.random() > 0.5,
		commissionForHost: Math.random() > 0.5,
		public: Math.random() > 0.5,
		formatId: Math.floor(Math.random() * 5),
		categoryId: Math.floor(Math.random() * 10),
		subCategoryId: Math.floor(Math.random() * 10),
		ianaTimezone: 'UTC',
		showMap: Math.random() > 0.5,
		hasVendors: Math.random() > 0.5,
		ticketStubDescription: `Ticket Stub for Event ${id}`,
		serviceFee: Math.random() * 100,
		commission: Math.random() * 100,
		taxRateId: Math.floor(Math.random() * 100),
		hasSoldTickets: Math.random() > 0.5,
		isEventFinished: Math.random() > 0.5,
		allowNoCategories: Math.random() > 0.5,
		bankAccountId: generateRandomNumber(3),
		donationIncludeTickets: Math.random() > 0.5,
		productType: 1,
		indefinite: Math.random() > 0.5,
		originalImage: `https://dummyimage.com/600x400/000/fff&text=Original+Image+${id}`,
		imageString: `https://dummyimage.com/600x400/000/fff&text=Image+String+${id}`,
		thumbnail: `https://dummyimage.com/200x200/000/fff&text=Thumbnail+${id}`,
		hasStreaming: Math.random() > 0.5,
		croppedImage: `https://dummyimage.com/600x400/000/fff&text=Cropped+Image+${id}`,
		hasOnlineContent: Math.random() > 0.5,
		eventEndDateChanged: Math.random() > 0.5,
		fundraiserCertificate: null,
		pdfLogoUrl: generateRandomString(15),
		allowRefundProtect: Math.random() > 0.5,
		displayBannerInEmails: Math.random() > 0.5,
		lockedOnPrivate: Math.random() > 0.5,
		creationStep: 3,
	};
}

export function getMailAccounts(amount: number): MailAccount[] {
	const mailAccounts: MailAccount[] = [];
	for (let i = 0; i < amount; i++) {
		const mailAccount = {
			id: i,
			name: Names[Math.floor(Math.random() * Names.length)],
			fromName: Names[Math.floor(Math.random() * Names.length)],
			fromEmail: Emails[Math.floor(Math.random() * Emails.length)],
			type: 1,
			typeName: 'Mandrill',
			apiKey: generateRandomNumber(10).toString(),
		};
		mailAccounts.push(mailAccount);
	}
	return mailAccounts;
}

export function generateZARCurrency() {
	return {
		ianaTimezone: '',
		name: 'Rand',
		iso: 'ZAR',
		baseUtcOffset: '',
		displayName: 'Rand',
		symbol: 'R',
		id: 1,
		domainId: 1,
		defaultCommission: 1,
		defaultServiceFee: 1,
	};

}

export function generateEventImage(id: number) {
	return {
		itemId: id,
		itemTypeId: 1,
		userId: id,
		dateAdded: new Date().toISOString(),
		mimeType: generateRandomString(20),
		contentLength: generateRandomNumber(10),
		url: generateRandomString(20),
		thumbUrl: `https://dummyimage.com/600x400/000/fff&text=Image+String+${id}`,
		id: id,
	};
}

export function generateChartData(seriesName: string) {
	const randomDate = (year: number): string => {
		const start = new Date(year, 0, 1);
		const end = new Date(year, 11, 31);
		const randomChartDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
		const randomTime = new Date(randomChartDate.getTime() + Math.random() * (86400000 - 1)); // 86400000 ms in a day
		return randomTime.toISOString();
	};

	const dummyObject = {
		name: seriesName,
		series: Array.from({ length: 2000 }, () => ({
			value: Math.floor(Math.random() * 20000 + 100),
			name: randomDate(2023),
		})),
	};

	return dummyObject;

}

export function createDummyRegistrationUsers(
	amount: number,
	search: string = null
): EventRegistration[] {
	let dummyRegistrationUsers = [];

	for (let i = 0; i < amount; i++) {
		dummyRegistrationUsers.push({
			id: i,
			name: Names[Math.floor(Math.random() * Names.length)],
			surname: Names[Math.floor(Math.random() * Names.length)],
			email: Emails[Math.floor(Math.random() * Emails.length)],
			identificationNumber: '123123123123123',
			status: randomRegistrationStatus(),
		});
	}

	if (search) {
		const lowerCaseSearchTerm = search.toLowerCase();
		dummyRegistrationUsers = dummyRegistrationUsers.filter(eventCard =>
			eventCard.name.toLowerCase().includes(lowerCaseSearchTerm)
		);
	}
	return dummyRegistrationUsers;
}
