import { Component, Inject, Optional, forwardRef } from '@angular/core';
import { DateFilterOption } from '../../models/element.model';
import { NgAisIndex, NgAisInstantSearch, TypedBaseWidget } from 'angular-instantsearch';
import { connectNumericMenu } from 'instantsearch.js/es/connectors';
import { NumericMenuWidgetDescription, NumericMenuConnectorParams } from 'instantsearch.js/es/connectors/numeric-menu/connectNumericMenu';

@Component({
	selector: 'app-algolia-date-select',
	templateUrl: './algolia-date-select.component.html',
	styleUrls: ['./algolia-date-select.component.sass'],
})
export class AlgoliaDateSelectComponent extends TypedBaseWidget<NumericMenuWidgetDescription, NumericMenuConnectorParams> {
	state: NumericMenuWidgetDescription['renderState'] = {
		items: [],
		refine: () => null,
		createURL: () => '',
		hasNoResults: false,
		canRefine: false,
		sendEvent: () => null,
	};

	selectedOption: string | null = null;

	items: DateFilterOption[] = [
		{
			label: 'All Dates',
			start: undefined,
			end: undefined,
		},
		{
			label: 'Today',
			start: this.getTodayEvents(0, 0, 0, 0),
			end: this.getTodayEvents(23, 59, 59, 999),
		},
		{
			label: 'This weekend',
			start: this.getThisWeekendEvents(5, 0, 0),
			end: this.getThisWeekendEvents(7, 23, 59),
		},
		{
			label: 'Next 7 days',
			start: this.getTodayEvents(0, 0, 0, 0),
			end: this.getNextSevenDays(),
		},
		{
			label: 'Next 30 days',
			start: this.getTodayEvents(0, 0, 0, 0),
			end: this.getNextThirtyDays(),
		},
	];

	constructor(
		@Inject(forwardRef(() => NgAisIndex)) @Optional() public parentIndex: NgAisIndex,
		@Inject(forwardRef(() => NgAisInstantSearch)) public instantSearchInstance: NgAisInstantSearch
	) {
		super('NumericMenu');
		this.createWidget(connectNumericMenu, {
			attribute: 'Dates',
			items: this.items.map((item) => ({ label: item.label, start: item.start, end: item.end })),
		});
	}

	public ngOnInit() {
		super.ngOnInit();
	}

	onSelectionChange(value: string) {
		if (this.state.refine) {
			this.state.refine(value);
		}
	}

	getTodayEvents(hour: number, minute: number, second: number, millisecond: number): number {
		const today = new Date();
		today.setHours(hour, minute, second, millisecond);
		return today.getTime() / 1000;
	}

	getThisWeekendEvents(dayOfWeek: number, hour: number, minute: number): number {
		const now = new Date();
		const eventDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute);
		const todaysDay = now.getDay();

		let daysUntilEvent: number;
		if (dayOfWeek === 5) {
			if (todaysDay >= 5) {
				daysUntilEvent = 5 - todaysDay;
			} else {
				daysUntilEvent = (5 - todaysDay + 7) % 7;
			}
		} else if (dayOfWeek === 7) {
			if (todaysDay <= 7 && todaysDay >= 5) {
				daysUntilEvent = 7 - todaysDay;
			} else {
				daysUntilEvent = (7 - todaysDay + 7) % 7;
			}
		}

		eventDate.setDate(now.getDate() + daysUntilEvent);
		return Math.floor(eventDate.getTime() / 1000);
	}

	getNextSevenDays(): number {
		const now = new Date();
		now.setDate(now.getDate() + 7);
		return now.getTime() / 1000;
	}

	getNextThirtyDays(): number {
		const now = new Date();
		now.setDate(now.getDate() + 30);
		return now.getTime() / 1000;
	}
}
