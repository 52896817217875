<div
	class="l-container"
	[ngClass]="{'mobile': isMobile || isSmall}"
>
	<ais-instantsearch
		[config]="config"
		class="l-instant-search"
	>
		<div
			class="l-search-container"
			[ngClass]="{'mobile': isMobile || isSmall}"
		>
			<ais-search-box class="w-100 l-search-input" />
			<div
				class="l-filter-button"
				(click)="toggleFilterPanel()"
				*ngIf="isMobile || isSmall"
			>
				<i class="fa fa-solid fa-filter l-filter-toggle"></i>
			</div>
		</div>
		<div class="l-content-container">
			<div
				class="l-filter-panel"
				*ngIf="!isMobile && !isSmall"
			>
				<div class="l-filter-heading">Upcoming Events</div>
				<app-event-filter
					[categoryFilter]='categoryFilter'
					[locationFilter]='locationFilter'
				></app-event-filter>
				<ais-configure [searchParameters]="{ query: searchTerm, hitsPerPage: 12}"></ais-configure>
				<ais-index indexName="products"></ais-index>
			</div>
			<div
				class="l-event-list"
				[ngClass]="{'mobile': isMobile || isSmall}"
			>
				<ais-hits>
					<ng-template let-hits="hits">
						<app-algolia-stats></app-algolia-stats>
						<ol
							class="l-event-container"
							[ngClass]="{'mobile': isMobile || isSmall}"
						>
							<li
								*ngFor="let hit of hits"
								class="l-event-item"
							>
								<a
									class="l-event-item-wrapper"
									[href]="hit.ProductUrl + (hit.ProductUrl.includes('?') ? '&' : '?') + 'ref=events-list'"
								>
									<img
										[src]="hit.ImageUrl_Medium | safeUrl"
										[alt]="hit.ProductName"
										class="l-event-image"
									/>
									<div class="l-event-content">
										<div class="l-hit-name">
											<div
												[ngClass]="{ 'l-title-scroll': hit.ProductName.length > 24 }"
												[ngStyle]="GetScrollTimeStyle(hit.ProductName.length)"
											>
												<ais-highlight
													attribute="ProductName"
													[hit]="hit"
												></ais-highlight>
											</div>
										</div>
										<div class="l-hit-venue">
											{{ hit.VenueName}}
										</div>
										<div class="l-date-container">
											<div class="l-date">
												<span *ngIf="hit.Dates && hit.Dates.length > 1">Runs from</span>
												{{ hit.DateFrom * 1000 | date: 'fullDate'}}
											</div>
											<div class="l-date">{{ hit.DateFrom * 1000 | date: 'HH:mm'}}</div>
										</div>
									</div>
								</a>
							</li>
						</ol>
					</ng-template>
				</ais-hits>
				<div class="l-pagination-container">
					<ais-pagination
						class="l-pagination"
						[padding]="isMobile ? 1 : isSmall ? 2 : 3"
						[showFirst]="isMobile ? false : true"
						[showLast]="isMobile ? false : true"
					/>
				</div>
			</div>
		</div>
		<div
			class="l-sidebar"
			[class.show]="showSidebar"
			*ngIf="isMobile || isSmall"
		>
			<div class="l-header">
				<img
					class="l-logo"
					src="/app-elements/assets/logo.png"
					alt="logo"
				>
				<button
					(click)="toggleFilterPanel()"
					class="l-button"
				>
					<i class="fa fa-solid fa-times l-close"></i>
				</button>

			</div>
			<div class="l-sidebar-content">
				<app-event-filter
					isMobile="true"
					[categoryFilter]='categoryFilter'
					[locationFilter]='locationFilter'
				></app-event-filter>
				<ais-configure [searchParameters]="{ query: searchTerm, hitsPerPage: 6}"></ais-configure>
				<ais-index indexName="products"></ais-index>
			</div>
		</div>
		<div
			class="l-overlay"
			*ngIf="showSidebar"
			(click)="toggleFilterPanel()"
		></div>
	</ais-instantsearch>
</div>