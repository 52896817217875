import { Component, Input, OnInit } from '@angular/core';
import { NavItem } from '../../models/element.model';
import { CONTACT_EMAIL, CONTACT_PHONE, FOOTER } from '../../utils/consts';
import { URLCreator } from '@services/url/url.dictionary';
import { DomainData } from '@app/elements/models/domainData.model';
import { DataService } from '@app/elements/services/data.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.sass'],
})
export class FooterComponent implements OnInit{
	@Input() facebook: string;
	@Input() twitter: string;
	@Input() instagram: string;
	@Input() linkedin: string;
	@Input() contactPhone = CONTACT_PHONE;
	@Input() contactEmail = CONTACT_EMAIL;
	@Input() copyrightYear = new Date().getFullYear().toString();

	domainData = new DomainData();
	navItems: NavItem[] = FOOTER;
	logo = 'https://www.quicket.co.za/content/images/static/01_PrimaryLogo_4c.svg';
	link = URLCreator.createEvent();

	constructor(
		private dataService: DataService
	) {}
	ngOnInit(): void {
		this.handleDomainData();
	}
	handleDomainData() {
		this.dataService.getDomainDetails().pipe(take(1)).subscribe(
			({payload: data}) => {
				if (data){
					this.domainData = {...data};
					if (this.domainData.telephoneNumber === null || this.domainData.telephoneNumber.length === 0) {
						this.domainData.telephoneNumber = '+27 21 424 9308';
					}
					if (this.domainData.domainName === 'local'){
						this.domainData.domainName = 'co.za';
					}
					this.updateDomainData();
				}
			}
		);
	}

	updateDomainData() {
		this.contactEmail = 'support@quicket.' + this.domainData.domainName;
		this.contactPhone = this.domainData.telephoneNumber;
	}
}
