<header class="l-container">
	<div
		*ngIf="!isMobile; else mobileHeader"
		class="l-content"
	>
		<a href="/">
			<img
				class="l-logo"
				[src]="QUICKET_LOGO"
				alt="logo"
			>
		</a>
		<div
			*ngIf="!isMobile && !hideSearch"
			class="l-search-container"
			[class.tablet]="isSmall"
		>
			<div class="ais-InstantSearch">
				<ais-instantsearch
					[config]="config"
					class="l-instant-search"
				>
					<div class="searchbox">
						<ais-configure [searchParameters]="searchParameters"></ais-configure>
						<ais-index indexName="products"></ais-index>
						<ais-index indexName="categories"></ais-index>
						<ais-index indexName="localities"></ais-index>
						<app-algolia-autocomplete></app-algolia-autocomplete>
					</div>
				</ais-instantsearch>
			</div>
		</div>
		<nav
			*ngIf="!isSmall"
			class="l-nav-container"
		>
			<ng-container *ngFor="let item of navItems">
				<ng-container *ngIf="item.href; else dropdownNav">
					<a
						class="l-nav-item"
						[href]="item.href"
						[id]="item.label"
					>
						{{ item.label }}
					</a>
				</ng-container>
				<ng-template #dropdownNav>
					<button
						id="header-dropdown-menu"
						class="l-dropdown-nav"
						#button
						[matMenuTriggerFor]="dropdown"
						#dropdownTrigger="matMenuTrigger"
						(mouseenter)="handleButtonEnter(dropdownTrigger)"
						(mouseleave)="handleButtonLeave(dropdownTrigger)"
					><span class="l-nav-item "><span class="l-title-text">{{ item.label }}</span>
							<i class="fa fa-chevron-down"></i>
						</span>
					</button>

					<mat-menu
						#dropdown="matMenu"
						direction="down"
						yPosition="below"
						class="l-menu l-nav-header-menu"
					>
						<span
							(mouseenter)="handleMenuEnter()"
							(mouseleave)="handleMenuLeave(dropdownTrigger)"
						>
							<ng-container *ngFor="let option of item.options">
								<li
									class="l-menu-item"
									mat-menu-item
								>
									<a
										[href]="option.href"
										[class]="'l-menu-link ' + (option.extraClass || '')"
									>
										{{ option.label }}
									</a>
								</li>
							</ng-container>
						</span>
					</mat-menu>
				</ng-template>
			</ng-container>
			<div
				*ngIf="isLoading"
				class="l-spinner-container"
			>
				<div class="l-page-loader">
					<mat-spinner></mat-spinner>
				</div>
			</div>
			<a
				[href]="loginRoute"
				id="login-button"
				*ngIf="!userName && !isLoading"
			>
				<button class="g-primary-button l-login-button">LOGIN</button>
			</a>
		</nav>
		<div
			*ngIf="isSmall"
			class="l-mobile-menu-toggle"
		>
			<mat-icon
				class='ms-2 g-pointer'
				(click)="toggleSideNav()"
			>menu</mat-icon>
		</div>
	</div>
	<ng-template #mobileHeader>
		<div
			*ngIf="!searchActive; else mobileSearch"
			class="l-content"
		>
			<div class="l-mobile-menu-toggle">
				<mat-icon
					class='g-pointer'
					(click)="toggleSideNav()"
				>menu</mat-icon>
			</div>
			<a href="/">
				<img
					class="l-logo mobile"
					[src]="QUICKET_LOGO"
					alt="logo"
				>
			</a>
			<mat-icon
				[ngClass]="{'hidden': hideSearch}"
				class="g-pointer l-search-icon"
				(click)="toggleSearch()"
			>search</mat-icon>
		</div>
		<ng-template #mobileSearch>
			<div class="l-mobile-search-container">
				<div class="ais-InstantSearch">
					<ais-instantsearch
						[config]="config"
						class="l-instant-search"
					>
						<div class="searchbox">
							<ais-configure [searchParameters]="searchParameters"></ais-configure>
							<ais-index indexName="products"></ais-index>
							<ais-index indexName="categories"></ais-index>
							<ais-index indexName="localities"></ais-index>
							<app-algolia-autocomplete isMobile="true">
							</app-algolia-autocomplete>
						</div>
					</ais-instantsearch>
				</div>
			</div>
			<button
				(click)="toggleSearch()"
				class="l-close-button"
			>
				<i class="fa fa-solid fa-times l-close"></i>
			</button>
		</ng-template>

	</ng-template>
</header>