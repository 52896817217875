import { Component, Input } from '@angular/core';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../../utils/consts';

@Component({
	selector: 'app-quicket-contacts',
	templateUrl: './quicket-contacts.component.html',
	styleUrls: ['./quicket-contacts.component.sass'],
})
export class QuicketContactsComponent {
	@Input() phone = CONTACT_PHONE;
	@Input() email = CONTACT_EMAIL;
	@Input() year = new Date().getFullYear().toString();
	@Input() isWhite = false;
}
