import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { SectionContent, Type } from '../../models/element.model';

@Component({
	selector: 'app-page-section',
	templateUrl: './page-section.component.html',
	styleUrls: ['./page-section.component.sass'],
})
export class PageSectionComponent implements OnInit {
	@Input() imageLeft = false;
	@Input() imageSource: string;
	@Input() textContent: SectionContent;
	@Input() centerContent = false;
	@Input() altButtonLink = '';
	isMobile = false;
	isSmall = false;
	private destroyed$ = new Subject<void>();

	type = Type;

	constructor(private breakpointObserver: BreakpointObserver) {}

	ngOnInit () {
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});
	}
}
