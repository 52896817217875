import { Component, Input } from '@angular/core';
import { LocationFilters } from '@app/elements/models/element.model';

@Component({
	selector: 'app-event-filter',
	templateUrl: './event-filter.component.html',
	styleUrls: ['./event-filter.component.sass'],
})
export class EventFilterComponent {
	@Input() isMobile = false;
	@Input() categoryFilter = '';
	@Input() locationFilter: LocationFilters = {};
	datePickerVisible = false;

	toggleDatePicker() {
		this.datePickerVisible = !this.datePickerVisible;
	}
}
