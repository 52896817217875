<div class="l-container" (click)="openTestimonialModal()">
	<div class="l-header-container">
		<img
			class="l-image"
			[src]="card.image"
		/>
		<div class="l-title-container">
			<div class="l-title">{{ card.title}} </div>
			<div class="l-sub-title">{{ card.subTitle}} </div>
			<div *ngIf="card.authorTitle" class="l-sub-title">{{ card.authorTitle }}</div>
		</div>
	</div>
	<div class="l-text-container">
		<div class="l-text">
			{{ textToShow }}<span *ngIf="seeMore" class="g-green">...see more</span>
		</div>
	</div>
</div>