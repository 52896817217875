<div class="l-container">
	<header class="l-main">
		<div class="l-head">
			<img
				class="l-image"
				[attr.src]="logo"
				alt="Logo"
			/>
			<div class="l-create-event-container l-web">
				Ready to create an event?
				<a
					class="l-button"
					[attr.href]="link"
				>Get Started</a>
			</div>
		</div>
		<nav class="l-content-container l-web">
			<div
				class="l-content"
				*ngFor="let item of navItems"
			>
				<div class="l-heading">
					{{ item.label }}
				</div>
				<ng-container *ngFor="let option of item.options">
					<a
						class="l-route"
						[href]="option.href"
						target="_blank"
					>{{ option.label }}</a>
				</ng-container>
				<ng-container *ngIf="item.label === 'Resources'">
					<div
						id="ot-sdk-btn"
						class="ot-sdk-show-settings g-pointer l-route l-cookie-settings"
					>Cookie Settings</div>
				</ng-container>
			</div>
			<div class="l-content">
				<div class="l-heading">
					Follow
				</div>
				<app-quicket-socials
					[facebook]="facebook"
					[twitter]="twitter"
					[instagram]="instagram"
					[linkedin]="linkedin"
				></app-quicket-socials>
			</div>
		</nav>
	</header>
	<aside class="l-mobile l-contacts">
		<app-quicket-contacts
			class="l-mobile-contact"
			[email]="contactEmail"
			[phone]="contactPhone"
			[year]="copyrightYear"
		></app-quicket-contacts>
	</aside>
	<footer class="l-footer l-web">
		<app-quicket-contacts
			class="l-web-contact"
			[email]="contactEmail"
			[phone]="contactPhone"
			[year]="copyrightYear"
			isWhite="true"
		></app-quicket-contacts>
	</footer>
	<footer class="l-footer l-mobile">
		<app-quicket-socials
			[facebook]="facebook"
			[twitter]="twitter"
			[instagram]="instagram"
			[linkedin]="linkedin"
			isWhite="true"
		></app-quicket-socials>
	</footer>
</div>