<div class="l-container l-web">
	<app-content-container
		class="l-content-container"
		[contents]="textContent"
	></app-content-container>
	<app-horizontal-carousel [cards]="cards" [type]="type" class="l-carousel"></app-horizontal-carousel>
</div>
<div class="l-container l-mobile">
	<app-content-heading
		[headingType]="textContent.headingType"
		[text]="textContent.headingText"
		[secondaryText]="textContent?.secondaryHeadingText"
	></app-content-heading>
	<app-horizontal-carousel [cards]="cards" [type]="type"></app-horizontal-carousel>
	<p>{{ textContent.contentText }}</p>
	<app-content-button
		[buttonType]="textContent.buttonType"
		[text]="textContent.buttonText"
		[link]="textContent.buttonLink"
	></app-content-button>
</div>