import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-image-container',
	templateUrl: './image-container.component.html',
	styleUrls: ['./image-container.component.sass'],
})
export class ImageContainerComponent {
	@Input() imageSource: string;
}
