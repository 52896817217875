<div
	class="l-container l-web"
	[class.l-center]="centerContent"
>
	<ng-container *ngIf="imageLeft; else textFirst">
		<app-image-container
			class="l-image-container"
			[imageSource]="imageSource"
		></app-image-container>
		<app-content-container
			class="l-content-container"
			[contents]="textContent"
		></app-content-container>
	</ng-container>
	<ng-template #textFirst>
		<app-content-container
			class="l-content-container"
			[contents]="textContent"
		></app-content-container>
		<app-image-container
			class="l-image-container"
			[imageSource]="imageSource"
		></app-image-container>
	</ng-template>
</div>
<div class="l-mobile-container l-mobile">
	<app-content-heading
		[headingType]="textContent.headingType"
		[text]="textContent.headingText"
		[secondaryText]="textContent?.secondaryHeadingText"
	></app-content-heading>
	<app-image-container
		class="l-image-container"
		[imageSource]="imageSource"
	></app-image-container>
	<p>{{ textContent.contentText }}</p>
	<app-content-button *ngIf="!altButtonLink && (!isMobile && !isSmall); else altButton"
		[buttonType]="type.PRIMARY"
		[text]="textContent.buttonText"
		[link]="textContent.buttonLink"
	></app-content-button>
	<ng-template #altButton>
		<app-content-button
		[buttonType]="type.PRIMARY"
		[text]="textContent.buttonText"
		[link]="altButtonLink.length > 0 ? altButtonLink : textContent.buttonLink"
		></app-content-button>
	</ng-template>
</div>