import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SectionContent, Type } from '../../../elements/models/element.model';
import { Currency } from '../../models/currencies.model';

@Component({
	selector: 'app-sales-estimate-currency-selector',
	templateUrl: './sales-estimate-currency-selector.component.html',
	styleUrls: ['./sales-estimate-currency-selector.component.sass'],
})
export class SalesEstimateCurrencySelectorComponent implements OnInit {
	@Input() currencies: Currency[] = [];
	@Output() currencySelected = new EventEmitter<Currency>();
	selectedCurrency: Currency;
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'TICKET SALES ESTIMATE',
		headingText: 'Ticket Sales',
		contentText:
		`Quicket offers two fees: commission and booking fees. 
		Typically, event organisers pay the commission, and ticket buyers pay the booking fees. 
		However, flexible pricing allows you to choose who pays which fee.`,
	};

	ngOnInit(): void {
		if (this.currencies) {
			this.setDefaultCurrency();
		}
	}

	onCurrencySelected = (currency: Currency) => {
		this.selectedCurrency = currency;
		this.currencySelected.emit(this.selectedCurrency);
	};

	setDefaultCurrency() {
		this.currencies.forEach(currency => {
			if (currency.isDefaultForDomain) {
				this.selectedCurrency = currency;
			}
		});
	}

}
