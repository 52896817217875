import { Component, Input } from '@angular/core';
import { SectionContent } from '../../models/element.model';

@Component({
	selector: 'app-content-container',
	templateUrl: './content-container.component.html',
	styleUrls: ['./content-container.component.sass'],
})
export class ContentContainerComponent {
	@Input() contents: SectionContent;
}
