<div class="l-container" id="features" #features >
	<div *ngIf="!isMobile && !isSmall; else FeatureScroll">
		<div class="l-heading">
			<app-content-heading
				[headingType]="contents.headingType"
				[text]="contents.headingText"
				[secondaryText]="contents.secondaryHeadingText"
			>
			</app-content-heading>
			<p>{{ contents.contentText }}</p>
		</div>
		<div class="l-card-container">
			<div
				*ngFor="let card of cards"
				class="l-cards"
			>
				<app-feature-card
					class="l-card"
					[card]="card"
					isGrid="true"
				>
				</app-feature-card>
			</div>
		</div>
	</div>
</div>
<ng-template #FeatureScroll>
	<div class="l-scroll-container">
		<app-content-heading
			[headingType]="contents.headingType"
			[text]="contents.headingText"
			[secondaryText]="contents?.secondaryHeadingText"
		></app-content-heading>
		<app-horizontal-carousel [cards]="cards" [type]="type"></app-horizontal-carousel>
		<p>{{ contents.contentText }}</p>
		<app-content-button
			[buttonType]="contents.buttonType"
			[text]="contents.buttonText"
			[link]="contents.buttonLink"
		></app-content-button>
	</div>
</ng-template>