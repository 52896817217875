import eventMocks from './event';
import membershipMocks from './membership';
import fileMocks from './file';
import organiserMocks from './organiser';
import { MockItemAPI } from '@app/models/common.model';

export default [
	...eventMocks,
	...membershipMocks,
	...fileMocks,
	...organiserMocks,
];

export const apiToMock: MockItemAPI[] = [
	// 'getEventCollectInfo',
	// 'setCollectInfoRequired',
	// 'setCollectInfoEnabled',
	// 'deleteCollectInfo',
	// 'getProfileCollectInfo',
	// 'upsertEventCollectInfo',
	// 'reorderCollectInfo',
	// 'updateAPIToken',
	// 'getEventMetadata',
	// 'getEvent',
	// 'getOrganiserProfiles',
	/* 'deleteOrganiserProfile', */
	/* 'getEventMetadata',
	'getEventDetails',
	'getEvent',
	'updateEventDetails', */
	/* 'getUserMyTickets',
	'updateFundraiserInvoice', */
	// 'getUserManageBooking',
	// 'getTotalSales',
	// 'getTotalOrders',
	// 'getSalesOverview',
	// 'getCurrentCampaigns',
	// 'getUpcomingEvents',
	// 'getPastEvents',
	// 'getProductsInfo',
	// 'getFilteredProducts',
	// 'getMailAccountsByUserId',
	// 'getEventRegistrationsResults',
];
