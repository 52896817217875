import { Component } from '@angular/core';
import { SectionContent, Type } from '../../models/element.model';
import { URLCreator } from '@services/url/url.dictionary';

@Component({
	selector: 'app-ticketing-platform-section',
	templateUrl: './ticketing-platform-section.component.html',
	styleUrls: ['./ticketing-platform-section.component.sass'],
})
export class TicketingPlatformSectionComponent {
	imageSource = '/app-elements/assets/TicketingPlatform_1.png';
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'The Art of Making Memories',
		headingText: 'Do Your Event Your Way. ',
		contentText: `From massive festivals, conferences, expos, and captivating attractions 
		to tailor-made events for schools, clubs, and intimate boutique affairs, Quicket has gained 
		the trust of over 200 000 events. `,
		buttonType: Type.PRIMARY,
		buttonText: 'CREATE EVENT',
		buttonLink: URLCreator.createEvent(),
	};
}
