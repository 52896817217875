import { Component } from '@angular/core';
import { SectionContent, Type } from '../../models/element.model';
import { URLCreator } from '@services/url/url.dictionary';

@Component({
	selector: 'app-landing-banner',
	templateUrl: './landing-banner.component.html',
	styleUrls: ['./landing-banner.component.sass'],
})
export class LandingBannerComponent {
	imageSource = '/app-elements/assets/Homepage_2_min.png';
	trustedImageSource = '/app-elements/assets/TrustedEvents.png';
	textContent: SectionContent = {
		headingType: Type.PRIMARY,
		headingText: 'Experience Awesome Events',
		contentText:
			'No hassles. No hold-ups. Only the joy of making great events happen.',
		buttonType: Type.SECONDARY,
		buttonText: 'Browse Events',
		buttonLink: URLCreator.eventsPage(),
	};
}
