import { MockItem } from '@app/models/common.model';
import { HttpRequestMethod } from '@app/models/http.model';

const mocks: MockItem[] = [
	{
		api: 'uploadContactsCSV',
		method: HttpRequestMethod.POST,
		url: /api\/file\/parsecsv/g,
		payload: [
			{
				id: 1,
				email: 'example@example.com',
				name: 'Name',
				surname: 'Surname',
			},
			{
				id: 2,
				email: 'example2@example.com',
				name: 'Name',
				surname: 'Surname',
			},
			{
				id: 3,
				email: 'example3@example.com',
				name: 'Name',
				surname: 'Surname',
			},
		],
	},
];

export default mocks;
