<div *ngIf="currencies && selectedCurrency; else loading" class="l-selector-container">
    <div class="l-content-container">
        <app-content-container [contents]="textContent"></app-content-container>
        <div  class="l-dropdown">
            <label>Currency</label>
            <mat-select [(ngModel)]="selectedCurrency" (ngModelChange)="onCurrencySelected(selectedCurrency)" class="l-selector">
                <mat-option *ngFor="let currency of currencies" [value]="currency">
                    {{currency.name}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <app-fees-percentage-card class="l-content-container"
        [selectedCurrency]="selectedCurrency"
        [pricing]="selectedCurrency.pricing"
    ></app-fees-percentage-card>
</div>
<ng-template #loading>
    <div class='l-upload-container'>
        <mat-spinner></mat-spinner>
    </div>
</ng-template>