<a
	*ngIf="buttonType === type.PRIMARY"
	class="l-primary-button"
	[href]="link"
>
	{{ text }}
</a>

<a
	*ngIf="buttonType === type.SECONDARY"
	class="l-secondary-button"
	[href]="link"
>
	{{ text }} <div class="l-icon-container">
		<i class="l-icon fa fa-solid fa-arrow-right"></i>
	</div>
</a>