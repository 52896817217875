import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';
import config from '@app/config';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, map, takeUntil } from 'rxjs';
import { Categories, LocationFilters } from '@app/elements/models/element.model';

const searchClient = algoliasearch(config.algoliaAppId, config.algoliaKey);

@Component({
	selector: 'app-browse-events',
	templateUrl: './browse-events.component.html',
	styleUrls: ['./browse-events.component.sass'],
})
export class BrowseEventsComponent implements OnInit, AfterViewInit {
	config = {
		indexName: 'products',
		searchClient,
	};

	isMobile = false;
	isSmall = false;
	showSidebar = false;
	url = '';
	categoryFilter = '';
	locationFilter: LocationFilters = {};
	searchTerm = '';

	private destroyed$ = new Subject<void>();

	constructor(private renderer: Renderer2, private el: ElementRef, private breakpointObserver: BreakpointObserver) {}

	ngOnInit() {
		const loadingDiv = this.el.nativeElement.ownerDocument.getElementById('loading');
		if (loadingDiv) {
			this.renderer.removeChild(loadingDiv.parentNode, loadingDiv);
		}

		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});

		this.url = window.location.href;
		if (this.url.includes('?')) {
			this.getQueryParams(this.url);
		} else {
			this.getSegmentsAfterEvents(this.url);
		}
	}

	ngAfterViewInit() {
		const paginationContainer = document.querySelector('.ais-Pagination-list');

		if (paginationContainer) {
			const addClickListeners = () => {
				const paginationLinks = paginationContainer.querySelectorAll('.ais-Pagination-item a');
				paginationLinks.forEach((link) => {
					link.addEventListener('click', (event) => {
						event.preventDefault();
						setTimeout(() => {
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}, 200);
					});
				});
			};

			addClickListeners();

			const observer = new MutationObserver(() => {
				addClickListeners();
			});

			observer.observe(paginationContainer, { childList: true });
		}
	}

	getQueryParams(urlString: string) {
		const url = new URL(urlString);
		const queryParams: Record<string, string> = {};
		url.searchParams.forEach((value, key) => {
			queryParams[key] = value;
		});
		this.categoryFilter = this.getEnumKeyByValue(Categories, queryParams['categories']);
		if (!this.categoryFilter) {
			this.searchTerm = queryParams['search'];
		}
	}

	getSegmentsAfterEvents(urlString: string) {
		const url = new URL(urlString);
		let pathSegments = url.pathname.split('/').filter((segment) => segment);
		const eventsIndex = pathSegments.indexOf('events');
		if (eventsIndex !== -1) {
			pathSegments = pathSegments.slice(eventsIndex + 1);
			this.locationFilter = this.createLocationObject(pathSegments);
		}
	}

	toggleFilterPanel() {
		this.showSidebar = !this.showSidebar;
	}

	GetScrollTimeStyle(wordCount: number) {
		return {
			transition: Math.floor(Math.sqrt(wordCount) - 3).toString() + 's',
		};
	}

	getEnumKeyByValue(enumObj: any, value: number | string): string | undefined {
		const keys = Object.keys(enumObj).filter((x) => enumObj[x] === value);
		return keys.length > 0 ? keys[0] : undefined;
	}

	toTitleCase(str: string): string {
		return str
			.split('-')
			.map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
			.join(' ');
	}

	convertArrayToTitleCase(arr: string[]): string[] {
		return arr.map(this.toTitleCase);
	}

	createLocationObject(arr: string[]): LocationFilters {
		const titleCasedArray = this.convertArrayToTitleCase(arr);
		const locationObject: { country?: string; region?: string; city?: string } = {};

		if (titleCasedArray.length >= 1) {
			locationObject.country = titleCasedArray[0];
		}
		if (titleCasedArray.length >= 2) {
			locationObject.region = titleCasedArray[1];
		}
		if (titleCasedArray.length >= 3) {
			locationObject.city = titleCasedArray[2];
		}

		return locationObject;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
