import { Component, Input } from '@angular/core';
import { FeatureCard } from '../../models/element.model';

@Component({
	selector: 'app-blog-card',
	templateUrl: './blog-card.component.html',
	styleUrls: ['./blog-card.component.sass'],
})
export class BlogCardComponent {
	@Input() card: FeatureCard;

	handleNavigate() {
		window.open(this.card.link, '_blank');
	}

}
