import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DataService } from '../../services/data.service';
import { Subject, map, take, takeUntil } from 'rxjs';
import { HEADER, USER_NAV_OPTIONS } from '../../utils/consts';
import { NavItem } from '../../models/element.model';
import { URLCreator } from '@services/url/url.dictionary';
import { OverlayService } from '@services/overlay/overlay.service';
import { MobileSideNavComponent } from '../../components/mobile-side-nav/mobile-side-nav.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import algoliasearch from 'algoliasearch/lite';
import config from '@app/config';
import * as Sentry from '@sentry/angular';
import { QUICKET_LOGO } from '@app/utils/consts';

const searchClient = algoliasearch(config.algoliaAppId, config.algoliaKey);

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() hideSearch: string;

	navItems: NavItem[] = HEADER;
	navMenuItems: NavItem[] = USER_NAV_OPTIONS;
	enteredButton = false;
	isMatMenuOpen = false;
	prevButtonTrigger: MatMenuTrigger;
	menuLeaveTimeout: ReturnType<typeof setTimeout>;
	buttonLeaveTimeout: ReturnType<typeof setTimeout>;
	loginRoute = URLCreator.login(encodeURIComponent(URLCreator.myAccount()));
	isLoading = true;
	searchActive = false;

	QUICKET_LOGO = QUICKET_LOGO;

	userName: string;

	isMobile = false;
	isSmall = false;

	config = {
		indexName: 'products',
		searchClient,
	};

	public searchParameters = {
		query: '',
		hitsPerPage: 3,
	};

	private destroyed$ = new Subject<void>();

	constructor(private dataService: DataService,
		private overlayService: OverlayService,
		private breakpointObserver: BreakpointObserver) { }

	ngOnInit(): void {
		this.dataService
			.getLoggedInUser()
			.pipe(take(1))
			.subscribe(({ payload: email }) => {
				if (email?.email) {
					this.userName = email.email;
					Sentry.getIsolationScope().setUser({ email: email.email });
					this.navItems.push({ label: this.userName, options: this.navMenuItems });
				} else {
					Sentry.getIsolationScope().setUser(null);
				}
				this.isLoading = false;
			});

		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});
	}

	handleMenuEnter() {
		this.isMatMenuOpen = true;
	}

	handleMenuLeave(menuLeaveTrigger: MatMenuTrigger) {
		this.menuLeaveTimeout = setTimeout(() => {
			if (!this.enteredButton) {
				this.isMatMenuOpen = false;
				menuLeaveTrigger.closeMenu();
			} else {
				this.isMatMenuOpen = false;
			}
		}, 400);
	}

	handleButtonEnter(buttonEnterTrigger: MatMenuTrigger) {
		if (this.prevButtonTrigger && this.prevButtonTrigger !== buttonEnterTrigger) {
			this.prevButtonTrigger.closeMenu();
		}
		if (!this.isMatMenuOpen) {
			this.enteredButton = true;
			this.isMatMenuOpen = true;
			this.prevButtonTrigger = buttonEnterTrigger;
			buttonEnterTrigger.openMenu();
		}
	}

	handleButtonLeave(buttonLeaveTrigger: MatMenuTrigger) {
		this.buttonLeaveTimeout = setTimeout(() => {
			if (!this.isMatMenuOpen) {
				buttonLeaveTrigger.closeMenu();
			}
			this.enteredButton = false;
		}, 400);
	}

	toggleSideNav() {
		this.overlayService.open(MobileSideNavComponent, { email: this.userName, fullHeight: true });
	}

	toggleSearch() {
		this.searchActive = !this.searchActive;
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
