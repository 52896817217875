import { MockItem } from '@app/models/common.model';
import { HttpRequestMethod } from '@app/models/http.model';
const mocks: MockItem[] = [
	{
		api: 'verifyToken',
		method: HttpRequestMethod.GET,
		url: /api\/membership\/info/g,
		payload: {
			id: 1010,
			name: 'vasyl.trs@gmail.com',
			email: 'vasyl.trs@gmail.com',
			userName: 'vasyl.trs@gmail.comt',
			encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
			seatsDesignerKey: 'f2de83ea-5a79-4c06-ba0f-7c17e3bd8da6',
			contactInfo: {
				firstName: 'Ben',
				surname: null,
				phone: null,
			},
		},
	},
	{
		api: 'createContactList',
		method: HttpRequestMethod.POST,
		url: /api\/membership\/contacts/g,
		payload: {
			itemsImported: 2,
			contactListId: 1,
			contactListName: '123',
		},
	},
	{
		api: 'getPreviousContactLists',
		method: HttpRequestMethod.GET,
		url: /api\/membership\/contactlists/g,
		payload: {
			previousContactLists: [
				{
					name: 'First',
					contactsCount: 10,
					id: 1,
				},
				{
					name: 'Second',
					contactsCount: 1,
					id: 2,
				},
				{
					name: 'Third',
					contactsCount: 0,
					id: 3,
				},
			],
		},
	},
	{
		api: 'getPreviousEvents',
		method: HttpRequestMethod.GET,
		url: /api\/users\/previousevents$/g,
		payload: [
			{
				id: 1,
				eventName: 'Cool corgies party',
				numberOfAttendees: 5,
			},
			{
				id: 2,
				eventName: 'Cool cats party',
				numberOfAttendees: 5,
			},
		],
	},
	{
		api: 'getPreviousEventsAttendees',
		method: HttpRequestMethod.GET,
		url: /api\/membership\/previousevents\/attendees/g,
		payload: [
			{
				name: 'Ben',
				surname: 'Valdemar',
				email: 'ben.valdemar@mail.com',
			},
			{
				name: 'Ben',
				surname: 'Valdemar',
				email: 'ben.valdemar@mail.com',
			},
			{
				name: 'Ben',
				surname: 'Valdemar',
				email: 'ben.valdemar@mail.com',
			},
		],
	},
	{
		api: 'getBankAccounts',
		method: HttpRequestMethod.GET,
		url: /api\/membership\/bankaccounts/g,
		payload: [
			{
				id: 1,
				bankName: 'ING',
				holder: 'Ben Dover',
				number: '0000 0000 0000 0000',
				isDefault: true,
				currency: {
					id: 1,
					iso: 'ZAR',
				},
			},
			{
				id: 2,
				bankName: 'ING 2',
				holder: 'Ben Dover 2',
				number: '0000 0000 0000 0000',
				isDefault: true,
				currency: {
					id: 1,
					iso: 'ZAR',
				},
			},
			{
				id: 3,
				bankName: 'ING 3',
				holder: 'Ben Dover 3',
				number: '0000 0000 0000 0000',
				isDefault: true,
				currency: {
					id: 1,
					iso: 'ZAR',
				},
			},
			{
				id: 4,
				bankName: 'ING 4',
				holder: 'Ben Dover 3',
				number: '0000 0000 0000 0000',
				isDefault: true,
				currency: {
					id: 2,
					iso: 'USD',
				},
			},
		],
	},
	{
		api: 'getBanks',
		method: HttpRequestMethod.GET,
		url: /api\/membership\/banks/g,
		payload: [
			{
				id: 1,
				name: 'ING',
				universalCode: '0000',
				isMobileMoney: true,
				currency: {
					id: 1,
					iso: 'ZAR',
				},
			},
			{
				id: 2,
				name: 'ING 2',
				universalCode: null,
				isMobileMoney: false,
				currency: {
					id: 1,
					iso: 'ZAR',
				},
			},
			{
				id: 3,
				name: 'ING 3',
				universalCode: '0000',
				isMobileMoney: false,
				currency: {
					id: 1,
					iso: 'ZAR',
				},
			},
			{
				id: 4,
				name: 'ING 4',
				universalCode: '0000',
				isMobileMoney: false,
				currency: {
					id: 2,
					iso: 'USD',
				},
			},
		],
	},
	{
		api: 'addBankAccount',
		method: HttpRequestMethod.POST,
		url: /api\/membership\/bankaccounts/g,
		payload: {
			id: 4,
			bankName: 'ING 4',
			holder: 'Ben Dover 4',
			number: '0000 0000 0000 0000',
			currency: {
				id: 1,
				iso: 'ZAR',
			},
			isDefault: true,
		},
	},
	{
		api: 'getUserManageBooking',
		method: HttpRequestMethod.GET,
		url: /api\/account\/orders\/(?<id>\w+)/g,
		payload: ({ id }) => ({
			id: Number(id),
			encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
			productId: 102253,
			scheduleItemId: 77174,
			eventName: 'This is my event',
			eventLink: 'quicket.local/events/102249-covid-event',
			dateFrom: '2022-08-05T20:00:00Z',
			dateTo: '2022-08-05T21:00:00Z',
			amountPaid: 'R300',
			eventImage: '/content/images/events/upload/thumbs/Q15_300_300.jpg',
			googleMapsUrl: null,
			canRefund: true,
			hasSchedules: true,
			venueName: 'Quicket',
			seatPublicKey: '3861ac38-12f6-4a4a-9788-29843064ce45',
			refundProtectType: 1,
			canTransfer: true,
			canTicketResale: true,
			hasSeatingChart: false,
			allowOrphanSeats: false,
			showSeatLabels: true,
			productStatus: 10,
			enableContactHost: false,
			allowTicketEditing: true,
			isPaid: true,
			seatingPricing: [
				{
					category: 'General',
					ticketTypes: [
						{
							ticketType: 'Free General',
							price: 'R0',
							ticketId: '171354',
						},
					],
				},
				{
					category: 'VIP',
					ticketTypes: [
						{
							ticketType: 'Free VIP',
							price: 'R0',
							ticketId: '171354',
						},
					],
				},
			],
			invoice: {
				line1: '',
				line2: '',
				line3: '',
				line4: '',
				line5: '',
			},
			schedules: [
				{
					canTransfer: true,
					dateFrom: '2022-01-16T12:00:00Z',
					dateTo: '2022-01-16T13:00:00Z',
					id: 77164,
					name: 'Schedule 1',
				},
				{
					canTransfer: true,
					dateFrom: '2022-01-30T12:00:00Z',
					dateTo: '2022-01-30T13:00:00Z',
					id: 77176,
					name: 'Schedule 2',
				},
				{
					canTransfer: false,
					dateFrom: '2022-02-06T12:00:00Z',
					dateTo: '2022-02-06T13:00:00Z',
					id: 77167,
					name: 'Schedule 3',
				},
			],
			organiser: {
				id: 6,
				name: 'Widabiff',
				phone: '021 424 9308',
				twitter: '',
				facebook: 'https://www.facebook.com/Quicket/',
			},
			tickets: [
				{
					id: 1234567,
					isStreamingTicket: false,
					ticketType: 'VIP Ticket',
					streamAccessCode: 'ABABA',
					streamAccessUrl: '',
					seat: 'K-8',
					isValid: true,
					isDonation: true,
					questions: [
						{
							question: 'First name',
							answer: 'Liz',
						},
						/* {
								question: 'Surname',
								answer: 'Breslin',
							}, */
						{
							question: 'Email',
							answer: 'liz@quicket.co.za',
						},
					],
				},
				{
					id: 1234589,
					isStreamingTicket: true,
					ticketType: 'Adult',
					streamAccessCode: 'BABAB',
					streamAccessUrl: 'quicket.local/events/102249-covid-event',
					seat: 'K-9',
					isValid: true,
					isDonation: false,
					questions: [
						{
							question: 'First name',
							answer: 'Elizabeth',
						},
						{
							question: 'Surname',
							answer: '',
						},
						{
							question: 'Email',
							answer: 'elizabeth@quicket.co.za',
						},
					],
				},
			],
		}
		),
	},
	{
		api: 'updateManageBooking',
		method: HttpRequestMethod.POST,
		url: /api\/account\/orders\/(?<id>\w+)/g,
		payload: ({ id }) => ({
			id: 77166,
		}),
	},
	{
		api: 'updateFundraiserInvoice',
		method: HttpRequestMethod.POST,
		url: /api\/account\/orders\/(?<id>\w+)\/fundraiserinvoice/g,
		payload: ({ id }) => ({
			id,
			productId: 102229,
			productName: 'Fundraiser for raising money',
			eventLink: 'http://quicket.local/events/102222-scheduled-streaming/',
			reference: 'REF04069692',
			venue: null,
			showMap: false,
			gpsCoordinates: null,
			googleMapsUrl: 'www.google.com/maps',
			dateFrom: '2022-01-20T10:00:00Z',
			dateTo: '2022-08-21T20:00:00Z',
			dateAdded: '2022-01-10T22:00:00Z',
			productType: 2,
			productStatus: 10,
			bookingAmount: 300,
			timeZoneId: '',
			EFTCutoff: '2022-01-10T22:00:00Z',
			bookingAmountFormatted: 'R300',
			isProvisional: false,
			paid: true,
			eventImage: 'http://quicket.local/content/images/events/upload/thumbs/Q15_300_300.jpg',
			encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
			invoice: {
				line1: 'Invoice updated',
				line2: 'VAT: 1234',
				line3: '',
				line4: '',
				line5: '',
			},
		}),
	},
	{
		api: 'getUserMyTickets',
		method: HttpRequestMethod.GET,
		url: /api\/account\/orders\/mytickets/g,
		payload: [
			{
				id: 4069680,
				productId: 102222,
				productName: 'Rocking the Daises 2022',
				eventLink: 'http://quicket.local/events/102222-scheduled-streaming/',
				reference: 'REF04069665',
				venue: 'Quicket office',
				showMap: true,
				gpsCoordinates: null,
				googleMapsUrl: 'www.google.com/maps',
				dateFrom: '2022-06-12T15:00:00Z',
				dateTo: '2022-06-12T21:00:00Z',
				dateAdded: '2021-12-05T22:00:00Z',
				productType: 1,
				productStatus: 50,
				bookingAmount: 1500,
				timeZoneId: '',
				EFTCutoff: null,
				bookingAmountFormatted: 'R1500',
				isProvisional: false,
				paid: true,
				eventImage: 'http://quicket.local/content/images/events/upload/thumbs/Q5_300_300.jpg',
				encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
				invoice: null,
			},
			{
				id: 4069665,
				productId: 102222,
				productName: 'AfrikaBurn 2022',
				eventLink: 'http://quicket.local/events/102222-scheduled-streaming/',
				reference: 'REF04069665',
				venue: 'A normal venue name',
				showMap: false,
				gpsCoordinates: null,
				googleMapsUrl: 'www.google.com/maps',
				dateFrom: '2022-02-05T20:00:00Z',
				dateTo: '2022-02-07T10:00:00Z',
				dateAdded: '2022-01-05T22:00:00Z',
				productType: 1,
				productStatus: 10,
				bookingAmount: 100,
				timeZoneId: '',
				EFTCutoff: null,
				bookingAmountFormatted: 'R100',
				isProvisional: false,
				paid: true,
				eventImage: 'http://quicket.local/content/images/events/upload/thumbs/Q4_300_300.jpg',
				encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
				invoice: null,
			},
			{
				id: 4069675,
				productId: 102226,
				productName: 'A provisional booking',
				eventLink: 'http://quicket.local/events/102222-scheduled-streaming/',
				reference: 'REF04069665',
				venue: 'Quicket office',
				showMap: false,
				gpsCoordinates: null,
				googleMapsUrl: 'www.google.com/maps',
				dateFrom: '2022-08-20T10:00:00Z',
				dateTo: '2022-08-21T20:00:00Z',
				dateAdded: '2022-01-10T22:00:00Z',
				productType: 1,
				productStatus: 10,
				bookingAmount: 0,
				timeZoneId: '',
				EFTCutoff: '2022-01-10T22:00:00Z',
				bookingAmountFormatted: 'R200',
				isProvisional: true,
				paid: false,
				eventImage: 'http://quicket.local/content/images/events/upload/thumbs/Q15_300_300.jpg',
				encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
				invoice: null,
			},
			{
				id: 4069692,
				productId: 102229,
				productName: 'Fundraiser for raising money',
				eventLink: 'http://quicket.local/events/102222-scheduled-streaming/',
				reference: 'REF04069692',
				venue: null,
				showMap: false,
				gpsCoordinates: null,
				googleMapsUrl: 'www.google.com/maps',
				dateFrom: '2022-01-20T10:00:00Z',
				dateTo: '2022-08-21T20:00:00Z',
				dateAdded: '2022-01-10T22:00:00Z',
				productType: 2,
				productStatus: 10,
				bookingAmount: 300,
				timeZoneId: '',
				EFTCutoff: '2022-01-10T22:00:00Z',
				bookingAmountFormatted: 'R300',
				isProvisional: false,
				paid: true,
				eventImage: 'http://quicket.local/content/images/events/upload/thumbs/Q15_300_300.jpg',
				encrypt: 'YbdLQa/TXm4HTbmQbNCMVlKDbBaMC9bVzdPfVh2mhac=',
				invoice: {
					line1: 'Fundraiser',
					line2: '',
					line3: '',
					line4: '',
					line5: '',
				},
			},
		],
	},
	// update api request Post method
	{
		api: 'updateAPIToken',
		payload: '',
		method: HttpRequestMethod.POST,
		url: /api\/membership\/user\/new-api-token/g,
	},
];

export default mocks;
