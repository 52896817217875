import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';
import { CardType, FeatureCard, SectionContent, Type } from '../../../elements/models/element.model';
import { TICKETING } from '../../../elements/utils/consts';

@Component({
	selector: 'app-ticketing-feature-grid',
	templateUrl: './ticketing-feature-grid.component.html',
	styleUrls: ['./ticketing-feature-grid.component.sass'],
})
export class TicketingFeatureGridComponent implements OnInit {
	isMobile = false;
	isSmall = false;
	private destroyed$ = new Subject<void>();

	contents: SectionContent = {
		contentText: 'Create your event, your way. No hold-ups. No hassles. No hidden fees.',
		headingText: 'Features',
		secondaryHeadingText: 'Ticketing',
		headingType: Type.SECONDARY,
	};
	cards: FeatureCard[] = TICKETING;
	type = CardType.FEATURE;
	constructor(private breakpointObserver: BreakpointObserver) { }

	ngOnInit() {
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
