import { Component, Input } from '@angular/core';
import { FeatureCard } from '../../models/element.model';

@Component({
	selector: 'app-feature-card',
	templateUrl: './feature-card.component.html',
	styleUrls: ['./feature-card.component.sass'],
})
export class FeatureCardComponent {
	@Input() card: FeatureCard;
	@Input() isGrid = false;

}
