import { URLCreator } from '@services/url/url.dictionary';
import { FeatureCard, NavItem } from '../models/element.model';

export const CONTACT_PHONE = '+27 21 424 9308';
export const CONTACT_EMAIL = 'support@quicket.co.za';

export const TICKETING: FeatureCard[] = [
	{
		title: 'Reserved Seating',
		text: `Effortlessly create your perfect seating plan for any venue, from a small hall to a massive stadium, 
		with our drag-and-drop feature.`,
		image: '/app-elements/assets/icons/reserved-seating.svg',
	},
	{
		title: 'Ticket Scanning',
		text: `Use our free cutting-edge app to scan tickets on your mobile phone at the venue and access relevant 
		info on your Quicket profile.`,
		image: '/app-elements/assets/icons/ticket-scanning.svg',
	},
	{
		title: 'Recurring Events',
		text: 'Automatically reuse the same setup for multi-day, regular, or repeated events. No need to rebuild.',
		image: '/app-elements/assets/icons/recurring-events.svg',
	},
	{
		title: 'Real-Time Analytics',
		text: `Access comprehensive analytics directly from your Quicket dashboard, including site visits, sources, 
		sales reports, check-in reports, and much more.`,
		image: '/app-elements/assets/icons/real-time-analytics.svg',
	},
	{
		title: 'Integrated Tools',
		text: 'Utilise API integration to sell tickets directly from your site, expand and email your database, and track user journeys.',
		image: '/app-elements/assets/icons/integrated-tools.svg',
	},
	{
		title: 'Support',
		text: 'Get world-class, knowledgeable, practical help from real humans.',
		image: '/app-elements/assets/icons/support.svg',
	},
	{
		title: 'Event Discovery',
		text: 'Get world-class, knowledgeable, practical help from real humans.',
		image: '/app-elements/assets/icons/event-discovery.svg',
	},
	{
		title: 'Design Your Event Page',
		text: 'Customise a bespoke page with your brand colors, banners, visuals, links, social media, and event information.',
		image: '/app-elements/assets/icons/design-yourself.svg',
	},
	{
		title: 'Reviews',
		text: 'Receive event ratings and comments. Build a reliable rating system, manage reviews, and understand audience feedback.',
		image: '/app-elements/assets/icons/reviews.svg',
	},
	{
		title: 'Custom Data Collection',
		text: 'Customise and design a fully bespoke questionnaire to gather relevant info and insights from ticket buyers.',
		image: '/app-elements/assets/icons/custom-data.svg',
	},
	{
		title: 'Marketing Tools',
		text: 'Build promoter networks, offer discounts, incentives, invites, track communication, and more.',
		image: '/app-elements/assets/icons/marketing-tools.svg',
	},
	{
		title: 'One Step Checkout',
		text: 'Swift, simple ticket buying. No obstacles, no sign-up or login required.',
		image: '/app-elements/assets/icons/one-step-checkout.svg',
	},
];

export const TESTIMONIALS: FeatureCard[] = [
	{
		title: 'Woordfees',
		subTitle: 'Saartjie Botha',
		authorTitle: '- Director Toyota Stellenbosch Woordfees',
		image: '/app-elements/assets/testimonial_woordfees.jpg',
		text: `We have used Quicket as service provider in 2023.
			They provided excellent services continuously adapting to our needs.
			Their support to our team, artists and the public was outstanding.
			I can strongly recommend them to any festival.`,
	},
	{
		title: 'Steyn Entertainment (Rocking the Daisies)',
		subTitle: 'Dale Steyn',
		authorTitle: '- Managing Director Steyn Entertainment ',
		image: '/app-elements/assets/testimonial_RTD.jpg',
		text: `We have been thoroughly impressed with the quick and professional service we have received from Quicket.
		From the very beginning,
		their support team has been patient and accommodating, addressing our every need with utmost efficiency.
		Their dedication to customer satisfaction is evident at every level, right up to the management team,
		who have been nothing short of exceptional in ensuring our requirements are met promptly.
		Quicket's ticketing service has significantly enhanced our entertainment businesses,
		streamlining our ticketing processes and improving our event management capabilities.
		Their valuable insights and expertise have provided us with innovative solutions,
		making our events more successful. Dealing with Quicket has been an absolute pleasure,
		and we highly recommend their services.`,
	},
	{
		title: 'Ultra-Trail Cape Town',
		subTitle: 'Stuart McConnachie',
		image: '/app-elements/assets/testimonial_ultraTrail.jpg',
		text: `Since 2019, RMB Ultra-trail Cape Town has greatly enjoyed working with Quicket.
		Their professionalism and unwavering support have been instrumental in our event's success year after year.`,
	},
	{
		title: 'AfrikaBurn',
		subTitle: 'Julia Savage',
		authorTitle: '- Gate & Box Office Lead',
		image: '/app-elements/assets/testimonial_afrikaburn.jpg',
		text: `Every year the system changes (at our request) and every year the team at Quicket work tirelessly to meet our needs,
		and have now become an integral part of the Ticketing Team at AfrikaBurn.
		I would not hesitate at all to recommend Quicket to any Event Organiser looking for an online ticketing provider.`,
	},
];

export const BLOGS: FeatureCard[] = [
	{
		title: 'Why Small Events Matter',
		image: '/app-elements/assets/small_events_min.png',
		text: 'Boost your brand with meaningful engagement.',
		link: 'https://blog.quicket.com/2023/04/18/why-small-events-matter/',
	},
	{
		title: 'Why you should outsource event ticketing staff',
		image: '/app-elements/assets/why-you-should-outsource-event-ticketing-staff.png',
		text: 'Event ticketing staff could be the difference between a successful event and a flop. Find out more.',
		link: 'https://blog.quicket.com/2022/10/19/why-you-should-outsource-event-ticketing-staff/',
	},
	{
		title: 'Event Academy: Resources to get you successfully started on Quicket',
		image: '/app-elements/assets/resources-quicket-get-successfully-started-quicket.png',
		text: 'Getting started on a new ticketing system, or using ticketing for the first time can be daunting.',
		link: 'https://blog.quicket.com/2020/03/13/resources-quicket-get-successfully-started-quicket/',
	},
	{
		title: 'How to run your door for an event of under 500 people',
		image: '/app-elements/assets/run-event-door-under-500-people.png',
		text: 'What to plan for when running the door for your event and you\'re expecting up to 500 guests to arrive.',
		link: 'https://blog.quicket.com/2019/07/02/run-event-door-under-500-people/',
	},
	{
		title: 'Turn your ticket buyers into your promoters the Quicket way',
		image: '/app-elements/assets/promote-event-offer-manage-incentives.png',
		text: 'Promoting your event and working within your available budget is certainly a challenge.',
		link: 'https://blog.quicket.com/2019/05/21/promote-event-offer-manage-incentives/',
	},
];

const resourcesOptions: NavItem[] = [
	{ label: 'Help Centre', href: URLCreator.helpCentre(), target: '_blank' },
	{ label: 'Blog', href: URLCreator.blog() },
	{ label: 'Community', href: URLCreator.community() },
];

const companyOptions: NavItem[] = [
	{ label: 'Join Our Team', href: URLCreator.careers() },
	{ label: 'Contact Us', href: URLCreator.contactUs() },
	{ label: 'Privacy Policy', href: URLCreator.privacyPolicy() },
	{ label: 'Terms & Conditions', href: URLCreator.terms() },
];

const ticketsNavItem = () => ({ label: 'Create Events', href: URLCreator.eventFeaturesPage() });
const helpNavItem = () => ({ label: 'Help', href: URLCreator.helpCentre() });

export const HEADER: NavItem[] = [{ label: 'Find Events', href: URLCreator.eventsPage() }, ticketsNavItem(), helpNavItem()];

export const USER_NAV_OPTIONS: NavItem[] = [
	{ label: 'CREATE EVENT', href: URLCreator.createEvent(), target: '_self', extraClass: 'l-highlighted l-top-menu-item' },
	{ label: 'MY TICKETS', href: URLCreator.manageTicketDashboard(), target: '_self' },
	{ label: 'ORGANISER HUB', href: URLCreator.orgDashboard(), target: '_self' },
	{ label: 'MY ACCOUNT', href: URLCreator.userProfile(), target: '_self' },
	{ label: 'LOG OUT', href: URLCreator.logout(), target: '_self', extraClass: 'l-dangerous l-bottom-menu-item' },
];

export const FOOTER: NavItem[] = [
	{ label: 'Solutions', options: [ticketsNavItem()] },
	{ label: 'Resources', options: resourcesOptions },
	{ label: 'Company', options: companyOptions },
];

export const SIDENAV_ITEMS: NavItem[] = [
	{ label: 'Find Events', href: URLCreator.eventsPage() },
	ticketsNavItem(),
	{ label: 'Resources', options: resourcesOptions },
	{ label: 'Company', options: companyOptions },
];

export const SIDENAV_ITEMS_LOGGED_IN: NavItem[] = [
	{ label: 'Home', options: USER_NAV_OPTIONS },
	{ label: 'Find Events', href: URLCreator.eventsPage() },
	ticketsNavItem(),
	{ label: 'Resources', options: resourcesOptions },
	{ label: 'Company', options: companyOptions },
];
