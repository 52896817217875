import { Component } from '@angular/core';
import { SectionContent, Type } from '../../../elements/models/element.model';
import { URLCreator } from '@services/url/url.dictionary';

@Component({
	selector: 'app-understand-section',
	templateUrl: './understand-section.component.html',
	styleUrls: ['./understand-section.component.sass'],
})
export class UnderstandSectionComponent {
	imageSource = '/app-elements/assets/Ticketing_Understand_2.png';
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'ANALYTICS ',
		headingText: 'Understand',
		contentText: 'Access real-time reports, analytics, and reviews with our cutting-edge event dashboards.',
		buttonType: Type.PRIMARY,
		buttonText: 'CREATE EVENT',
		buttonLink: URLCreator.createEvent(),
	};
}
