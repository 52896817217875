import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';
import { BlogCardComponent } from './components/blog-card/blog-card.component';
import { BlogSectionComponent } from './components/blog-section/blog-section.component';
import { ContentButtonComponent } from './components/content-button/content-button.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { ContentHeadingComponent } from './components/content-heading/content-heading.component';
import { FeatureCardComponent } from './components/feature-card/feature-card.component';
import { ImageContainerComponent } from './components/image-container/image-container.component';
import { LandingBannerComponent } from './components/landing-banner/landing-banner.component';
import { PageSectionComponent } from './components/page-section/page-section.component';
import { StreamingSectionComponent } from './components/streaming-section/streaming-section.component';
import { TestimonialCardComponent } from './components/testimonial-card/testimonial-card.component';
import { TicketingBannerComponent } from './components/ticketing-banner/ticketing-banner.component';
import { HowItWorksSectionComponent } from './components/how-it-works-section/how-it-works-section.component';
import { TicketingPromoteSectionComponent } from './components/ticketing-promote-section/ticketing-promote-section.component';
import { OnTheDaySectionComponent } from './components/on-the-day-section/on-the-day-section.component';
import { UnderstandSectionComponent } from './components/understand-section/understand-section.component';
import { TicketingFeaturesComponent } from './components/ticketing-features/ticketing-features.component';
import { TicketingFeatureGridComponent } from './components/ticketing-feature-grid/ticketing-feature-grid.component';
import { TestimonialsSectionComponent } from './components/testimonials-section/testimonials-section.component';
import { TicketingFeaturesSectionComponent } from './components/ticketing-features-section/ticketing-features-section.component';
import { TicketingPlatformSectionComponent } from './components/ticketing-platform-section/ticketing-platform-section.component';
import { FooterComponent } from './element/footer/footer.component';
import { HomePageComponent } from './element/home-page/home-page.component';
import { CreateEventsComponent } from './element/create-events-page/create-events-page.component';
import { HeaderComponent } from './element/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { TicketSalesEstimateComponent } from './components/ticket-sales-estimate/ticket-sales-estimate.component';
import {
	SalesEstimateCurrencySelectorComponent
} from './components/sales-estimate-currency-selector/sales-estimate-currency-selector.component';
import { SalesEstimateCalculatorComponent } from './components/sales-estimate-calculator/sales-estimate-calculator.component';
import { FeesPercentageCardComponent } from './components/fees-percentage-card/fees-percentage-card.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { APIModule } from '@app/api/api.module';
import { QuicketSocialsComponent } from './components/quicket-socials/quicket-socials.component';
import { QuicketContactsComponent } from './components/quicket-contacts/quicket-contacts.component';
import { HorizontalCarouselComponent } from './components/horizontal-carousel/horizontal-carousel.component';
import { MobileSideNavComponent } from './components/mobile-side-nav/mobile-side-nav.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NgAisModule } from 'angular-instantsearch';
import { AlgoliaAutocompleteComponent } from './components/algolia-autocomplete/algolia-autocomplete.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { BrowseEventsComponent } from './element/browse-events/browse-events.component';
import { AlgoliaDateSelectComponent } from './components/algolia-date-select/algolia-date-select.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AlgoliaCalendarRangeComponent } from './components/algolia-calendar-range/algolia-calendar-range.component';
import { AlgoliaStatsComponent } from './components/algolia-stats/algolia-stats.component';
import { AlgoliaRefinementListComponent } from './components/algolia-refinement-list/algolia-refinement-list.component';
import { EventFilterComponent } from './components/event-filter/event-filter.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ContactUsComponent } from './element/contact-us/contact-us.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as Sentry from '@sentry/angular';
import { MatDialogModule } from '@angular/material/dialog';
import { TestimonialModalComponent } from './components/testimonial-modal/testimonial-modal.component';
import { StoreService } from '@app/services/store/store.service';
import { StoreModule } from '@ngrx/store';

@NgModule({
	declarations: [
		LandingBannerComponent,
		TicketingPlatformSectionComponent,
		ImageContainerComponent,
		ContentContainerComponent,
		ContentHeadingComponent,
		ContentButtonComponent,
		PageSectionComponent,
		StreamingSectionComponent,
		TicketingFeaturesSectionComponent,
		FeatureCardComponent,
		TestimonialsSectionComponent,
		TestimonialCardComponent,
		BlogCardComponent,
		BlogSectionComponent,
		TicketingBannerComponent,
		HowItWorksSectionComponent,
		TicketingPromoteSectionComponent,
		OnTheDaySectionComponent,
		UnderstandSectionComponent,
		TicketingFeaturesComponent,
		TicketingFeatureGridComponent,
		FooterComponent,
		HomePageComponent,
		TicketSalesEstimateComponent,
		SalesEstimateCurrencySelectorComponent,
		SalesEstimateCalculatorComponent,
		FeesPercentageCardComponent,
		CreateEventsComponent,
		HeaderComponent,
		QuicketSocialsComponent,
		QuicketContactsComponent,
		HorizontalCarouselComponent,
		MobileSideNavComponent,
		AlgoliaAutocompleteComponent,
		SafeUrlPipe,
		TruncatePipe,
		BrowseEventsComponent,
		AlgoliaDateSelectComponent,
		AlgoliaCalendarRangeComponent,
		AlgoliaStatsComponent,
		AlgoliaRefinementListComponent,
		EventFilterComponent,
		ContactUsComponent,
		TestimonialModalComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MatFormFieldModule,
		MatMenuModule,
		MatSliderModule,
		MatSelectModule,
		MatIconModule,
		MatRippleModule,
		HttpClientModule,
		MatSidenavModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCheckboxModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		APIModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		StoreModule.forRoot({}),
		NgAisModule.forRoot(),
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		StoreService,
	],
	entryComponents: [
		FooterComponent,
		HomePageComponent,
		CreateEventsComponent,
		HeaderComponent,
		BrowseEventsComponent,
		ContactUsComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElementsModule {
	constructor(private injector: Injector) {
		const CreateEventsPageElement = createCustomElement(CreateEventsComponent, { injector: this.injector });
		customElements.define('app-create-events-page', CreateEventsPageElement);
		const FooterElement = createCustomElement(FooterComponent, { injector: this.injector });
		customElements.define('app-footer', FooterElement);
		const HeaderElement = createCustomElement(HeaderComponent, { injector: this.injector });
		customElements.define('app-header', HeaderElement);
		const HomePageElement = createCustomElement(HomePageComponent, { injector: this.injector });
		customElements.define('app-home-page', HomePageElement);
		const BrowseEventsElement = createCustomElement(BrowseEventsComponent, { injector: this.injector });
		customElements.define('app-browse-events', BrowseEventsElement);
		const ContactUsElement = createCustomElement(ContactUsComponent, { injector: this.injector });
		customElements.define('app-contact-us', ContactUsElement);
	}
	ngDoBootstrap() {}
}
