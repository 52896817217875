import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ElementsModule } from './elements.module';
import { register } from 'swiper/element/bundle';
import { environment } from 'environments/environment';
import { isDevMode, enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';
import config from '@app/config';

register();

if (environment.production){
	enableProdMode();
}

Sentry.init({
	dsn: config.sentryDSN,
	integrations: [
		// Registers and configures the Tracing integration,
		// which automatically instruments your application to monitor its
		// performance, including custom Angular routing instrumentation
		Sentry.browserTracingIntegration(),
	],

	environment: config.sentryEnv,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: isDevMode() ? 1.0 : 0.2,
});

platformBrowserDynamic().bootstrapModule(ElementsModule)
	.catch(err => console.log(err)); // eslint-disable-line no-console
