import { Component } from '@angular/core';
import { SectionContent, Type, FeatureCard, CardType } from '../../models/element.model';
import { TICKETING } from '../../utils/consts';

@Component({
	selector: 'app-ticketing-features-section',
	templateUrl: './ticketing-features-section.component.html',
	styleUrls: ['./ticketing-features-section.component.sass'],
})
export class TicketingFeaturesSectionComponent {
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		headingText: 'Redefine Your Ticketing.',
		secondaryHeadingText: 'Feature-filled & Power-packed.',
		contentText: 'Multiple features. Multiple uses. Create awesome events with Quicket.',
		buttonType: Type.PRIMARY,
		buttonText: 'VIEW ALL FEATURES',
		buttonLink: '/sell-tickets/#features',
	};

	cards: FeatureCard[] = TICKETING;
	type = CardType.FEATURE;

}
