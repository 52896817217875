<div class="l-container">
	<app-content-heading
		[headingType]="contents.headingType"
		[text]="contents.headingText"
		[secondaryText]="contents?.secondaryHeadingText"
	></app-content-heading>
	<p>{{ contents.contentText }}</p>
	<app-content-button
		[buttonType]="contents.buttonType"
		[text]="contents.buttonText"
		[link]="contents.buttonLink"
	></app-content-button>
</div>