import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SectionContent, Type } from '../../../elements/models/element.model';
import { Calculator } from '../../../elements/models/calculator.model';
import { Currency, Pricing } from '../../../elements/models/currencies.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-sales-estimate-calculator',
	templateUrl: './sales-estimate-calculator.component.html',
	styleUrls: ['./sales-estimate-calculator.component.sass'],
})
export class SalesEstimateCalculatorComponent implements OnInit, OnChanges {
	@Input() selectedCurrency: Currency;
	pricing: Pricing;
	isMobile = false;
	isSmall = false;
	private destroyed$ = new Subject<void>();

	orgCoversCommissionFee = true;
	orgCoversBookingFee = false;
	calculatorValues = new Calculator();
	totalCommissionFee = 0;
	totalBookingFee = 0;
	guestsPayPerOrder = 0;
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'Our Calculator',
		headingText: 'Ticket Sales Estimate',
	};
	constructor(private breakpointObserver: BreakpointObserver) {}

	ngOnInit() {
		this.onEventPricingChange();
		this.breakpointObserver
			.observe([Breakpoints.HandsetPortrait])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isMobile) => {
				this.isMobile = isMobile;
			});

		this.breakpointObserver
			.observe([Breakpoints.Small])
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroyed$)
			)
			.subscribe((isSmall) => {
				this.isSmall = isSmall;
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedCurrency && !changes.selectedCurrency.firstChange) {
			this.selectedCurrency = changes.selectedCurrency.currentValue;
		}
		this.onEventPricingChange();
	}

	onEventPricingChange() {
		this.pricing = this.selectedCurrency.pricing;
		const numGuests = this.calculatorValues.numberOfGuests;
		const price = this.calculatorValues.averageTicketPrice;
		const orderSize = this.calculatorValues.averageOrderSize;
		const numberOfOrders = numGuests / orderSize;

		this.calculatorValues.turnover = numGuests * price;

		const comm = this.selectedCurrency.symbol === 'R' ? this.pricing.commission * 1.15 * price : this.pricing.commission * price;

		let serviceFee = orderSize * this.pricing.serviceFee;
		if (price === 0) {
			serviceFee = 0;
		}

		this.totalBookingFee = this.orgCoversBookingFee ? serviceFee * numberOfOrders : serviceFee;

		this.calculatorValues.profit =
			this.calculatorValues.turnover -
			(this.orgCoversCommissionFee ? comm * numGuests : 0) -
			(this.orgCoversBookingFee ? this.totalBookingFee : 0);

		this.guestsPayPerOrder = (orderSize * price) + (this.orgCoversBookingFee ? 0 : serviceFee)
		+ (this.orgCoversCommissionFee ? 0 : Math.round(comm * orderSize * 100) / 100.0);

		this.totalCommissionFee = this.orgCoversCommissionFee ? Math.round(comm * numGuests * 100) / 100.0 : Math.round(comm * 100) / 100.0;
	}
}
