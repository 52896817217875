import { Component, Input } from '@angular/core';
import { Type } from '../../models/element.model';

@Component({
	selector: 'app-content-button',
	templateUrl: './content-button.component.html',
	styleUrls: ['./content-button.component.sass'],
})
export class ContentButtonComponent {
	type = Type;
	@Input() buttonType: string;
	@Input() text: string;
	@Input() link: string;
}
