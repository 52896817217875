<div class="l-container">
    <div class="l-content">
        <app-ticketing-banner></app-ticketing-banner>
        <app-how-it-works-section></app-how-it-works-section>
        <app-ticketing-promote-section></app-ticketing-promote-section>
        <app-on-the-day-section></app-on-the-day-section>
        <app-understand-section></app-understand-section>
        <app-ticketing-feature-grid></app-ticketing-feature-grid>
        <app-ticket-sales-estimate></app-ticket-sales-estimate>
    </div>
</div>