import { Component } from '@angular/core';
import { SectionContent, Type } from '../../../elements/models/element.model';
import { URLCreator } from '@services/url/url.dictionary';

@Component({
	selector: 'app-ticketing-promote-section',
	templateUrl: './ticketing-promote-section.component.html',
	styleUrls: ['./ticketing-promote-section.component.sass'],
})
export class TicketingPromoteSectionComponent {
	imageSource = '/app-elements/assets/Ticketing_Promote.png';
	textContent: SectionContent = {
		headingType: Type.SECONDARY,
		secondaryHeadingText: 'MARKETING ',
		headingText: 'Promote',
		contentText: `Elevate your marketing efforts with Quicket’s smart tools: social sharing, 
		tracking links, promotional codes, contact lists, invitations and more.`,
		buttonType: Type.PRIMARY,
		buttonText: 'CREATE EVENT',
		buttonLink: URLCreator.createEvent(),
	};
}
