import { Component, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DomainData } from '@app/elements/models/domainData.model';
import { DataService } from '@app/elements/services/data.service';
import { take } from 'rxjs';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.sass'],
})
export class ContactUsComponent {
	domainData = new DomainData();
	contactsLoading = true;
	sales = 'sales@quicket.co.za';
	support = 'support@quicket.co.za';
	organiser = 'organisers@quicket.co.za';
	mapSrc: SafeResourceUrl = this.domainData.googleMapsiFrameSrc;

	constructor(
		private sanitizer: DomSanitizer,
		private dataService: DataService,
		private renderer: Renderer2,
		private el: ElementRef
	) {}

	ngOnInit() {
		const loadingDiv = this.el.nativeElement.ownerDocument.getElementById('loading');
		if (loadingDiv) {
			this.renderer.removeChild(loadingDiv.parentNode, loadingDiv);
		}
		this.handleDomainData();
	}

	handleDomainData() {
		this.dataService.getDomainDetails().pipe(take(1)).subscribe(
			({payload: data}) => {
				if (data){
					this.domainData = {...data};
				}
				if (this.domainData.telephoneNumber === null || this.domainData.telephoneNumber.length === 0) {
					this.domainData.telephoneNumber = '+27 21 424 9308';
				}
				if (this.domainData.domainName === 'local'){
					this.domainData.domainName = 'co.za';
				}
				this.mapSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.domainData.googleMapsiFrameSrc);
				this.updateDomainData();
				this.contactsLoading = false;
			}
		);
	}

	updateDomainData() {
		const domain = this.domainData.domainName;
		this.sales = 'sales@quicket.' + domain;
		this.support = 'support@quicket.' + domain;
		this.organiser = 'organisers@quicket.' + domain;
	}
}
