import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
	selector: 'app-create-events-page',
	templateUrl: './create-events.component.html',
	styleUrls: ['./create-events.component.sass'],
})
export class CreateEventsComponent {
	constructor(private renderer: Renderer2, private el: ElementRef) {}

	ngOnInit() {
		const loadingDiv = this.el.nativeElement.ownerDocument.getElementById('loading');
		if (loadingDiv) {
			this.renderer.removeChild(loadingDiv.parentNode, loadingDiv);
		}
	}
}
