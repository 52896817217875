<div *ngIf="state?.items.length; else noFilters">
	<div *ngFor="let item of state.items">
		<div class="l-checkbox-container" (click)="toggleCheckbox(item.value, $event)">
			<span>{{ item.label | truncate:25 }} ({{ item.count }})</span>
			<mat-checkbox
				[checked]="item.isRefined"
				(click)="$event.stopPropagation()"
				(change)="onSelectionChange(item.value)"
			></mat-checkbox>			
		</div>
	</div>
	<button
	class="l-more"
		*ngIf="state.canToggleShowMore"
		(click)="toggleShowMore()"
	>{{ state.isShowingMore ? 'Show less' : 'Show more' }}</button>
</div>
<ng-template #noFilters>
	<p class="l-no-filters">No filters available</p>
</ng-template>