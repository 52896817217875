import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, OperatorFunction, catchError, of } from 'rxjs';
import { Currency } from '../models/currencies.model';
import { Email } from '../models/user.model';
import { RequestType, AsyncResponse, ResponseScheme } from '@app/models/http.model';
import { HttpService } from '@app/api/http/http.service';
import { ErrorCodes } from '@app/models/errorCode.model';
import { DomainData } from '../models/domainData.model';

@Injectable({ providedIn: 'root' })
export class DataService {
	constructor(private http: HttpService) {}

	getCurrencyData = (): AsyncResponse<Currency[]> =>
		this.http
			.makeRequest({
				url: '/api/currencies/pricing',
				type: RequestType.GET,
			})
			.pipe(catchError((error) => this.handleError<Currency[]>(error)) as OperatorFunction<any, ResponseScheme<Currency[]>>);

	getLoggedInUser = (): AsyncResponse<Email> =>
		this.http
			.makeRequest({
				url: '/api/membership/me',
				type: RequestType.GET,
			})
			.pipe(catchError((error) => this.handleError<Email>(error)) as OperatorFunction<any, ResponseScheme<Email>>);

	getDomainDetails = (): AsyncResponse<DomainData> => this.http.makeRequest<ResponseScheme<DomainData>>({
		url: 'api/info',
		type: RequestType.GET,
	}).pipe(
		catchError((error) => this.handleError<DomainData>(error))
	);

	private handleError<T>(error: HttpErrorResponse): Observable<ResponseScheme<T>> {
		const errors: { message: string; errorCode: ErrorCodes }[] = [];
		if (error.error instanceof ErrorEvent) {
			errors.push({
				message: `An error occurred: ${error.error.message}`,
				errorCode: ErrorCodes.Undefined,
			});
		} else {
			errors.push({
				message: `Server returned code ${error.status}, message was: ${error.message}`,
				errorCode: ErrorCodes.Undefined,
			});
		}

		return of({
			isSuccess: false,
			payload: null as T,
			errors: errors,
		});
	}
}
