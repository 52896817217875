<div class="l-container">
    <div class="l-header-icons">
        <div class="l-icon">
            <img [src]="data.card.image" />
        </div>
        <div class="l-button-container l-close-button"
            (click)="handleCancel()">
            <mat-icon>
                close
            </mat-icon>
        </div>
    </div>
    <div class="l-title-container">
        <div class="l-eventTitle">
            {{ data.card.title }}
        </div>
        <div class="l-authorTitle">
            {{ data.card.subTitle }}
        </div>
        <div class="l-authorTitle">
            {{ data.card.authorTitle }}
        </div>
    </div>
    <div class="l-text">
        {{ data.card.text }}
    </div>
</div>
